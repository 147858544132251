.usp-list {
    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 0;
        margin: 0;
        li{
            list-style: none;
            align-items: center;
            justify-content: center;
            margin: 0;
            font-size: 14px;
            color: #303030;
            display: flex;
            i{
                margin-right: 0.3rem;
                color: #37a447;
            }
        }
    }
    &__item {
        // add your own styling here
    }
}

.usp-top-wrapper{
    .widget.block.block-static-block{
        margin: 0;
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 1000px;
            margin: 0 auto;
            padding: 0;
            li{
                list-style: none;
                align-items: center;
                justify-content: center;
                display: flex;
                margin-left: 0.6rem;
                margin-right: 0.6rem;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: #ff7800;
                i{
                    margin-right: 0.3rem;
                    color: #ff7800;
                    font-size: 14px;
                }
            }
        }
    }
}

/* Mobile */
@include max-screen($screen__l){
    .usp-top-wrapper{
        padding: 0.75rem 0rem;
        display: none;
        .widget.block.block-static-block {
            ul{
                li{
                    margin: 0;
                    display: none;
                    &.slick-slide,
                    &:first-of-type {
                        display: flex;
                    }
                }
            }
        }
    }
    .usp-list{
        &__item{
            display: block !important;
            font-size: 12px !important;
            i{
                display: block;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 1033px) {
    .usp-top-wrapper{
        display: none;
    }
}
