/**
*
* Colors
*
*/

$form-element-input__border-color: #e0e6ee !default;

/**
*
*  Globals
*
*/

$polarcore-value-cart__color: #00c853 !default;
$polarcore-primary-button__border: 0px solid #e5e5e5 !default;
$polarcore-primary-button__color: #ffffff !default;
$polarcore-primary-button__background: $polarcore-primary__color !default;

$polarcore-primary-cart-button__border: darken(#ff4203, 15%)  !default;
$polarcore-primary-cart-button__color: #ffffff !default;
$polarcore-primary-cart-button__background: #ff4203 !default;
$polarcore-primary-cart-button__box-shadow: 0 4px 0px 0px darken(#ff4203, 15%) !default;
$polarcore__border-color: #ededed !default;

$polarcore-button__border: 1px solid $polarcore__border-color;


/**
*
* Form Element
*
*/

$form-element-input__height: 44px !default;
$form-element-input__border-color: #e0e0e0 !default; 
$form-element-input__border-radius: 2px !default;
$form-element-input__background: #f9f9f9 !default;

/**
*
* Navigation
*
*/

$navigation__background: transparent !default;
$navigation__level0-item-margin: 0 !default;
$navigation__item-color: $polarcore-secondary__color !default;
$navigation__item-color-hover: $polarcore-primary__color !default;
$navigation__item-color-active: $polarcore-primary__color !default;
$navigation__item-font-weight: 300 !default;
$navigation__item-background-hover: darken($navigation__background, 15%) !default;
$navigation__item-background-active: lighten($navigation__background, 5%) !default;
$navigation__item-border-color-active: darken($navigation__background, 15%) !default;
$navigation__item-border-width-active: 0 !default;

$navigation__level0-item-line-height: 30px !default; // Can't default may need to be moved

$navigation__submenu-background-hover: #f0f0f0 !default;
$navigation__submenu-border-color: rgba(0,0,0,0.05) !default;
$navigation__submenu-box-shadow: 0px 0px 20px rgba(0,0,0,0.15) !default;

$navigation__submenu-parent-icon: '\E145';
$navigation__submenu-parent-icon-position: right;
$navigation__submenu-parent-icon-color: #aaaaaa;
$navigation__submenu-parent-icon-size: 13px;

$navigation__submenu-arrow: true !default;
$navigation__submenu-arrow-size: 5px !default;
$navigation__submenu-arrow-left: 10px !default;

$polarcore-nav-sections__margin: 0 auto 0 0 !default; // margin-right: auto !default;

$polarcore-nav-sections-mobile__background-color: #f5f5f5 !default;
$polarcore-nav-sections-item-title__background: rgba(0,0,0,0.2) !default;
$polarcore-nav-sections-item-title-active__background: transparent !default;
$polarcore-nav-sections-item-title-link__color: #474747 !default;

$polarcore-navigation-link-mobile__color: #474747 !default;
$polarcore-nav-sections-item-switch-mobile__color: #474747 !default;

/**
*
* Modal
*
*/

$modal__box-shadow: none !default;

/**
*
* Page
*
*/

$page__background-color: #f9f9f9 !default;

/**
*
* Pager
*
*/

$pager__line-height: $indent__l !default;
$pager__font-size: 14px !default;
$pager__icon-font-size: 32px !default;
$pager__icon-font-margin: 0 !default;

/**
*
* Filter
*
*/

$filter-title-background-color: $color-gray94 !default;
$filter-link: $color-gray-darken4 !default;
$filter-link-hover: darken($filter-link, 30%) !default;
$filter-quantity: $color-gray52 !default;

/**
*
* Checkout
*
*/

$checkout-sidebar__columns: 4 !default;

/**
*
* Header
*
*/

$header__background-color: #ffffff !default; //v4
$header-panel__background-color: #ffffff !default; // "topline" v4
$header-panel__text-color: lighten($polarcore-secondary__color, 15%) !default; //v4
$polarcore-nav-toggle__color: #69707d !default;
$polarcore-nav-toggle-hover__color: darken(#69707d, 5%) !default;
$polarcore-nav-toggle-special__background: transparent !default;

$header-icons-color: $polarcore-secondary__color !default;
$header-icons-color-hover: lighten($polarcore-secondary__color, 2%) !default;
$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$polarcore-block-search-control__border: 1px solid $border-color__base !default;

/**
*
* Dropdown list
*
*/

$dropdown-list-pointer__border: #e2e2e2 !default; //????
$dropdown-list__background:  $polarcore-secondary__color !default;
$dropdown-list-item__hover: $color-gray91 !default;

/**
*
* Magento_Swatches module
*
*/

$polarcore-swatch-option-selected__border: 3px solid $polarcore-primary__color !default;
$polarcore-swatch-option-tooltip-image__border: 5px solid #f0f0f0 !default;

/**
*
* Footer
*
*/

$polarcore-footer__color: #f9f9f9 !default;

$footer__background-color: $polarcore-secondary__color !default;
$polarcore-footer__border-color: #666666;
$footer-links-color: $polarcore-footer__color !default;
$footer-links-color-hover: darken($polarcore-footer__color, 15%) !default;
$copyright__background-color: #1C1C1C !default;


/**
*
* Footer
*
*/

$polarcore-product-input-text-qty__border-radius: 0;

/**
*
*Toolbar
*
*/

$toolbar-mode-icon-font-size: 22px !default;
$toolbar-element-background: #ffffff !default;

// Luma Specific
$mobile-cart-padding: 15px !default;

// Potential new out-of-sync in blank/luma
$h1__margin-bottom: $heading__margin-bottom__base !default;
$account-title-border-color: $color-gray-middle2 !default;
$cart-price-color: $color-gray40 !default;
$dropdown-list-item__hover: $color-gray91 !default;

// Top Line
$font-size__top-line: 11px !default;

//  Search
$_search-advanced__display: none !default;
