.various-img {
  .various-img__container {

    margin: 0 -8px;
    display: flex;
  }


  .various-img__wrap {
    width: 50%;
    padding: 8px;
    margin: 0;
  }

  .various-img__wrap-container {
      margin: 0 -5px;
      display: flex;
      flex-wrap: wrap;
  }

  .various-img__link {
    display: block;
    overflow: hidden;

    &:hover {
      img {
        transform: scale(1.03);
      }
    }

    img {
      display: block;
      width: 100%;
      transition: 1s;
    }
  }

  .various-img__top {
    display: flex;
    margin: 0 -5px 10px;
  }

  .various-img__half {
    width: 50%;
    padding: 0 5px;
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }

    &--full {
      width: 100%;
    }
  }
}


/* TABLET */
@include screen($screen__m, $screen__l){
  .various-img {

  }
}

/* MOBILE */
@include max-screen($screen__m){
  .various-img {
    .various-img__container {
      flex-wrap: wrap;
    }

    .various-img__wrap {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
