.product-item{
    position: relative;
}
.tw-productlabels{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem !important;
    left: auto !important;
    .tw-productlabels__label{
        border-radius: 50%;
        &--discount{
            .tw-productlabels__text{
                background: $petworld-red-label__color;
            }
        }
        &--new{
            .tw-productlabels__text{
                background: #000;
                color: #fff;
            }
        }
        &--custom-3for2{
            position: absolute;
            width: 85px;
            right: -0.75rem;
            margin-top: 0.5rem;
        }
        &--custom-be_a_member{
            .custom-be_a_member{
                line-height: 15px !important;
                margin-right: 0 !important;
                margin-top: 0.5rem !important;
            }
        }
        &--custom-medlemrabatt,
        &--custom-be_a_member{
            background: $petworld-member-blue__color;
            width: 62px;
            height: 62px;
            position: relative;
            text-align: center;
            border-radius: unset;
            box-shadow: none;
            border-radius: 9px;
            .tw-productlabels__text,
            .tw-productlabelstext{
                color: #fff;
                text-transform: uppercase;
                z-index: 9;
                box-shadow: none;
                border-radius: 9px;
                padding: 0;
                &:before, &:after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 62px;
                    width: 62px;
                    border-radius: 9px;
                    background: $petworld-member-blue__color;
                    z-index: -1;
                }
                &:before{
                    -webkit-transform: rotate(30deg);
                    -moz-transform: rotate(30deg);
                    -ms-transform: rotate(30deg);
                    -o-transform: rotate(30deg);
                }
                &:after{
                    -webkit-transform: rotate(60deg);
                    -moz-transform: rotate(60deg);
                    -ms-transform: rotate(60deg);
                    -o-transform: rotate(60deg);
                }
                .price{
                    font-size: 16px;
                    font-weight: 600;
                }
                .text{
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    font-weight: 500;
                }
                .custom-medlemrabatt,
                .custom-be_a_member{
                    line-height: 20px;
                    margin-top: 0;
                    vertical-align: middle;
                    color: #fff;
                    letter-spacing: 1px;
                    font-size: 13px;
                    font-weight: 700;
                    margin-right: 0.4rem;
                    display: inline-block;
                }
            }
        }
    }
    &__text{
        border-radius: 50%;
        width: 70px;
        height: 70px;
        line-height: 59px;
        text-align: center;
        font-size: 16px;
        box-shadow: 1px 2px 5px rgba(0,0,0,0.35);
        margin-bottom: 0.5rem;
    }
}

/* MOBILE */
@include max-screen ($screen__m) {
    .tw-productlabels{
        &__label{
            &--custom-medlemrabatt{
                .custom-medlemrabatt{
                    font-size: 12px !important;
                }
            } 
        }
        &__text{
        
        }
    }
}
