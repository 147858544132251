.action,
button {
    border-radius: 4px;
    &:active {
        box-shadow: $button__shadow;
    }
}

button {
    background: #d0d9e5;
    box-shadow: 0px 2px 0px 0px darken(#d0d9e5 , 8%);
    &:hover {
        background: darken(#d0d9e5 , 8%);
        box-shadow: 0px 2px 0px 0px darken(#d0d9e5 , 16%);
    }
    &:active,
    &:focus {
        background: darken(#d0d9e5 , 8%);
        box-shadow: 0px 1px 0px 0px darken(#d0d9e5 , 16%);
        transform: translateY(1px);
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-button__border,
        $_button-border-hover: $polarcore-primary-button__border,
        $_button-border-active: $polarcore-primary-button__border,
        $_button-color: $polarcore-primary-button__color,
        $_button-background: $petworld-button__background-color,
        $_button-background-hover: darken($petworld-button__background-color, 8%),
        $_button-background-active: darken($petworld-button__background-color, 8%)
    );
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 4px;
	box-shadow: $petworld-button__box-shadow;
}

:not(.product-item) .action.primary.tocart {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-cart-button__border,
        $_button-border-hover: $polarcore-primary-cart-button__border,
        $_button-border-active: $polarcore-primary-cart-button__border,
        $_button-color: $polarcore-primary-button__color,
        $_button-background: $petworld-button__background-color,
        $_button-background-hover: darken($petworld-button__background-color, 8%),
        $_button-background-active: darken($petworld-button__background-color, 8%)
    );
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 4px;
    box-shadow: $petworld-button__box-shadow;
}

.product-item button.action.primary.tocart {
    @include lib-button-as-link();
    @include lib-icon-font(
        $_icon-font-content: '\e854',
        $_icon-font-size: 24px,
        $_icon-font-line-height: 24px,
        $_icon-font-text-hide: true,
        $_icon-font-color: $addto-color,
        $_icon-font-color-hover: $addto-hover-color,
        $_icon-font-position: before,
        $_icon-font-display: block
    );
    box-shadow: none;
}

.field-tooltip {
    .label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
