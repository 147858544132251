div#algolia-stats {
  display: none;
}

#algoliaAutocomplete {
    .aa-SubmitButton  {
        top: 50%;
        transform: translateY(-50%);
    }
}

.algolia-search-block {
  #algolia-searchbox {
    #search {
      margin: 0;
      border-radius: 50px;
      padding: 0;
      background: #efefef;
      border: 1px solid transparent;
      height: 34px;
      line-height: 34px;

      &:focus {
        border: 1px solid #efefef;
      }

      &::-webkit-input-placeholder {
        color: #464646;
      }

      &::-moz-placeholder {
        color: #464646;
      }

      &:-moz-placeholder {
        color: #464646;
      }

      &:-ms-input-placeholder {
        color: #464646;
      }
    }

    #algolia-glass {
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background: url("data:image/svg+xml;utf8,<svg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\'  fill=\'%464646\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M15.553 31.107c8.59 0 15.554-6.964 15.554-15.554S24.143 0 15.553 0 0 6.964 0 15.553c0 8.59 6.964 15.554 15.553 15.554zm0-3.888c6.443 0 11.666-5.225 11.666-11.668 0-6.442-5.225-11.665-11.668-11.665-6.442 0-11.665 5.223-11.665 11.665 0 6.443 5.223 11.666 11.665 11.666zm12.21 3.84a2.005 2.005 0 0 1 .002-2.833l.463-.463a2.008 2.008 0 0 1 2.833-.003l8.17 8.168c.78.78.78 2.05-.004 2.833l-.462.463a2.008 2.008 0 0 1-2.834.004l-8.168-8.17z\' fill-rule=\'evenodd\'/></svg>") no-repeat center right / 16px;
    }
  }
}

#algolia-autocomplete-container {
  #autocomplete-products-footer {
    display: block;
    left: 0;
    width: 50%;
    padding: 1rem;
  }
}

#maincontent #algolia-autocomplete-container .aa-dropdown-menu {
  z-index: 1000000 !important;
}

#algolia-searchbox {
  input.algolia-search-input {
    &:focus {
      background: white !important;
    }
  }
}

.algolia-instant-selector-results {

  #algolia_instant_selector {
    .text-ellipsis {
      white-space: normal;
    }

    .facet .name {
      padding: 8px;
      font-weight: 500;
      background-color: #5560a3;
      color: #ffffff !important;
      text-align: left;
      text-transform: uppercase;
    }

    .hits {
      .infos {
        background: transparent;
        margin: 0 0px 4px;
        padding: 0;

        .ais-body,
        strong {
          color: $petworld-cod-grey__color;
        }

        .sort-by-label {
          color: $petworld-cod-grey__color;
        }
      }
    }
  }

  #instant-search-results-container {
    .ais-stats--body {
      display: none !important;
    }

    .ais-hits {
      &--item {
        padding-left: 7px;
        padding-right: 7px;
      }

      .result-wrapper {
        .result {
          margin: 7px 0;
        }
      }
    }

    .ais-hits--item {
      padding-left: 7px;
      padding-right: 7px;

      .result-wrapper {
        .result {
          margin: 7px 0;
        }

        .result-sub-content {
          font-family: 'Open Sans', 'Arial', sans-serif;

          .result-title {
            color: $petworld-cod-grey__color;
            font-weight: 400;
          }

          .price-wrapper {
            color: $petworld-cod-grey__color;
            font-weight: 700;
            font-size: 18px;

            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row-reverse;

              .promotion {
                color: $petworld-red__color;
              }

              .before_special {
                font-size: 18px;
                font-weight: 400;
                color: $petworld-dark-grey__color;
                margin-right: 0.75rem;
              }
            }
          }
        }
      }
    }
  }

    #instant-search-bar-container {
        display: flex;
        margin-bottom: 10px;
        .instant-search-bar-label {
            display: flex;
            background-color: $petworld-blue__color;
            height: 40px;
            line-height: 24px;
            padding: 8px 12px;
            color: white;
            .ais-search-box--magnifier {
                padding-right: 10px;
            }
        }

        #instant-search-bar {
            width: calc(100% - 80px);
            border: solid 2px $petworld-blue__color;
        }
    }

  .ais-range-slider {
    &--handle-upper {
      .ais-range-slider--tooltip {
      }
    }

    &--header {
    }

  }
    .ais-search-box--magnifier svg {
        display: block;
        vertical-align: middle;
        height: 23px;
        width: 23px;
        fill: #FFFFFF;
        stroke: #FFFFFF;
    }

  .product-reviews-summary {
    display: none;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;

    .rating-summary {
      left: 2px;
    }
  }

  .price {
    margin-top: 0.5rem;
  }
}

#algolia-autocomplete-container {
  .footer_algolia {
    display: none;
  }

  .aa-dropdown-menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-top: none !important;

    .autocomplete-wrapper {
      flex-direction: initial;

      .category {
        font-size: 16px;
        padding: 0 10px 7px;
        border-bottom: 1px solid $petworld-blue__color;
        color: $petworld-cod-grey__color;
      }

      .col9, .col6 {
        border-left: none;

        .algoliasearch-autocomplete-price {
          font-weight: 500;
          font-size: 14px;
          margin-left: 60px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .after_special {
            color: #000;

            &.promotion {
              color: $petworld-special-price__color;
              margin-right: 0.5rem;
            }
          }

          .before_special {
            color: #000;
          }
        }
      }

      .col6 {
        width: 50%;
        height: 100%;
      }

      .col3 {
        border-left: solid 1px #eeeeee;
        width: 25%;
        min-width: auto;

        .aa-dataset-suggestions {
          .info-without-thumb {
            span.category-tag {
              color: #1979c3;
            }
          }
        }
      }

      .other-sections {
        .aa-suggestions {
          padding-top: 5px;

          .info {
            margin-left: 0;

            .categories {
              .category {
                padding: 0;
                border: none;
                font-size: 14px;
                font-weight: 600;
              }
            }

            .thumb {
              img {
                width: 70px;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

/* Infinite Scroll */
#amasty-shopby-product-list {
  .amscroll-load-button {
    background: $petworld-blue__color;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 1.5rem 4rem;
    width: fit-content;
    margin: 4rem auto 2.5rem auto;

    &:hover {
      background: darken($petworld-blue__color, 6%);
    }
  }
}

/* Desktop */
@include min-screen($screen__m) {
  .algolia-search-block {
    #algolia-searchbox {
      #search {
        padding: 0 1rem 0 50px;
      }

      #algolia-glass {
        left: 15px;
        right: auto;
      }

      .clear-query-autocomplete {
        bottom: 16px;
        right: 17px;
      }
    }
  }
  .algolia-instant-selector-results {
    #instant-search-results-container {
      .col-md-3 {
        padding-left: 0;
        padding-right: 0;

        .result-wrapper {
          padding-left: 7px;
          padding-right: 7px;
        }
      }

      .ais-hits--item {
        .result-wrapper {
          .result-sub-content {
            .result-title {
              font-size: 16px;
              min-height: 3.9em;
            }
          }
        }
      }
    }

    #algolia-left-container {
      .name {
        color: $white__color !important;
      }
    }
  }
  #algolia-autocomplete-container {
    .aa-dropdown-menu {
      right: 0 !important;
      width: 100% !important;
      left: auto !important;
    }
  }
}

/* MOBILE/TABLET */
@include max-screen($screen__l) {

  #algolia_instant_selector.with-facets #algolia-left-container {
    padding: 0;
  }
  #algolia_instant_selector .col-md-12 {
    padding: 0 !important;
  }
  .catalogsearch-result-index {
    .page-title-wrapper .page-title {
      font-size: 20px;
      margin-bottom: 0px;
    }
  }

  .block-search .block-content {
    margin: 0 0.5rem -0.5rem 0.5rem;

    #algolia-searchbox {
      .clear-query-autocomplete {
        bottom: 11px;
        right: 13px;
      }
    }
  }
  .algolia-search-block {
    #algolia-searchbox {
      #search {
        max-height: 40px;
        height: 40px;
      }
    }
  }

  #algolia-autocomplete-container {
    .aa-dropdown-menu {
      width: calc(100% - 2rem) !important;
      left: 1rem !important;

      .col6, .col9 {
        width: 100% !important;
      }
    }
  }
  #autocomplete-products-footer {
    width: 100% !important;
  }
  #instant-search-results-container {
    .ais-hits {
      &--item {
        display: inline-block;
        width: 50%;

        .col-sm-6 {
          padding-left: 0px;
          padding-right: 0px;

          .result-wrapper {
            .result {
              padding: 15px 10px;
            }

            .result-sub-content {
              .result-title {
                font-size: 12px;
                min-height: 5.2em;
              }
            }
          }
        }
      }
    }
  }
}

/* Mobile only */
@include max-screen($screen__m) {
  .autocomplete-wrapper {
    .col3 {
      width: 100% !important
    }

    #autocomplete-products-footer {
      position: static;
    }

    .other-sections {
      margin-bottom: 0rem !important;
    }
  }
  .algolia-search-block {
    #algolia-searchbox {
      #search {
        padding: 0 30px 0 10px;
        font-size: 12px;
      }

      #algolia-glass {
        right: 10px;
        left: auto;
      }
    }
  }
}

/* TABLET STYLING  */
@include screen($screen__s, $screen__l) {
  .autocomplete-wrapper {
    .col3 {
      width: 50% !important;
      border: none !important;
    }
  }
  .algolia-instant-selector-results {
    #instant-search-results-container {
      .ais-hits {
        display: flex;
        flex-wrap: wrap;

        &--item {
          width: 33.3333%;

          .col-sm-6 {
            width: 100%;
          }

          .result {
            padding: 8px;

            &-wrapper {
              width: 100%;

              .result-content {
                display: flex;
                flex-grow: 1;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
              }

              .result-sub-content {
                flex-wrap: wrap;
                width: 100%;
                justify-content: center;

                .result-title {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@include screen(1024px, 1239px) {
  .algolia-instant-selector-results #algolia_instant_selector .hits .infos {
    margin: 0 16px 4px !important;
  }
}
