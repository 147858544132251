// MagicZoom Mobile
@include max-screen($screen__m){
    .MagicToolboxSelectorsContainer{
        .mcs-item{
            padding: 0px 5px;
        }
        a{
            width: 14px;
            height: 14px;
            border-radius: 8px;
            background: none repeat scroll 0 0 #aaa;
            margin: 2px;
            cursor: pointer;
            display: inline-block;
            position: relative;
            text-indent: 9999px;
            vertical-align: middle;
            font-size: 0px;
            line-height: 0px;
            &.active-selector:before,
            &:hover:before {
                width: 8px;
                height: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                margin: 3px 3px;
                content: '';
                position: relative;
                display: block;
                text-indent: 9999px;
                background: #fff !important;
            }
        }
        img{
            display: none;
            box-shadow: none;
        }
    }
}
