.breadcrumbs {
    @include lib-breadcrumbs($_icon-font-size: $breadcrumbs-icon-font-size);
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;
}

@include min-screen($screen__m) {
    .breadcrumbs {
        padding-left: 0;
        padding-right: 0;
    }
}
