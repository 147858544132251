//  Layout
$layout-column__sidebar-width: 2.5;
$_breadcrumbs-container-margin: 0;

// General
$font-family__base: "Roboto", Helvetica, sans-serif;

//  Navigation
$navigation__level0-item-line-height: 47px;
$navigation__level0-item-font-size: 15px;

$product-name-link__color: #2c313e !default;
$product-name-link__color__active: #2c313e !default;
$product-name-link__color__hover: #2c313e !default;
$product-name-link__color__visited: #2c313e !default;

$_button-color: #606675;
$border-color__base: #e0e6ee;
$button__background: #e0e6ee;
$button__border: 0;
$button__border-radius: 0;
// $button__hover__color: $button__color;
$button__hover__background: darken($button__background, 5%);
$button__active__background: darken($button__background, 5%);

$rating-icon__color: #f0f0f0;
$rating-icon__active__color: #fff305;

/**
*
* Layout
*
*/

$layout-indent__width: 1rem;
$layout__max-width: 1440px;
$layout__max-width-wrap: 1440px;

/**
*
* PolarCore Tabs mode
*
*/

$polarcore-tabs__mode: 'default';
