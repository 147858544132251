.minicart-wrapper.active {
	.p2-qty-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		.qty {
			&-decrease,
			&-increase {
				height: 32px;
				width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #c8c8c8;
				font-size: 18px;
				font-weight: 600;
				cursor: pointer;
				user-select: none;
				transition: background 0.25s ease;
				&:hover,
				&:active {
					background: darken(#ffffff, 5%);
				}
			}
		}
		input {
			height: 32px;
			width: 32px;
			border-left: 0;
			border-right: 0;
			margin: 0;
			&:focus {
				box-shadow: none;
			}
			&.valid {
				border-color: #c8c8c8;
			}
		}
	}
    .block-minicart {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        background: #f2f2f2;
        &:before {
            top: -14px;
            border-color: transparent transparent #f4f4f4 transparent;
        }
        &:after {
            top: -16px;
        }
        .block-title {
            display: block;
            text-transform: uppercase;
            margin: -0.5rem 0 1rem 0;
            span.qty {
            }
        }
        .block-content {
            display: flex;
            flex-direction: column;
            margin-top: -2rem;
            margin-bottom: 0;
        }
        .details-qty{
            display: flex;
            .label{
                display: none;
            }
            button{
                background: $petworld-blue__color;
                color: #fff;
                border-bottom: 2px solid darken($petworld-blue__color, 12%);
            }
        }
        .block-content{
            .subtotal{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                .label{
                    display: block;
                    span{
                        font-size: 1.2rem;
                        margin-right: 0;
                        &:after{
                            content: ":";
                        }
                    }
                }
            }
            > .actions {
                > .primary {
                    margin: 0.5rem 0 0.25rem 0;
                    width: 100%;
                }
            }
        }
        .product.actions{
            .primary{
                display: none;
            }
        }
        .product-item-details{
            .product-item-name{
                a{
                    color: $petworld-cod-grey__color;
                    font-size: 1rem;
                }
            }
        }

        button#btn-minicart-close {
            &:before {
                font-size: 40px;
            }
        }

        button#top-cart-btn-checkout {
            position: absolute;
            top: 8px;
            right: 10px;
            width: 50%;
            padding: .5rem;
            font-size: 0.9rem;
        }

        .minicart-items-wrapper {
            background: #fff;
            height: inherit !important;
            border: 1px solid #dfe6ee;
            max-height: 350px;
            overflow-x: hidden;
        }

        .items-total {
            display: flex;
            font-size: 18px;
            margin: 1rem 0 0.5rem;
            font-weight: 700;
            span.count {
                margin-right: 5px;
            }
        }

        .subtotal {
            order: 10;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: -0.5rem 0 -1rem 0;
            span.label{
                margin-right: .5rem;
                font-weight: 600;
                span{
                    margin-right: -.5rem;
                }
            }
        }

        .minicart-wrapper .minicart-widgets {
            display: none;
            margin: 0;
        }

        .secondary {
            span {
                background-image: none;
                background: $petworld-blue__color;
                border: 0;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                font-weight: 700;
                margin: 0;
                padding: 7px 15px;
                width: auto;
                font-size: 14px;
                line-height: normal;
                box-sizing: border-box;
                vertical-align: middle;
                padding: 0.5rem 1rem;
                text-transform: uppercase;
                border-bottom: 3px solid darken($petworld-blue__color, 12%);
            }
        }

        div#minicart-widgets {
            display: none;
            margin-top: 0;
        }

    }
}


/* MOBILE */
@include max-screen($screen__m){
    .minicart-wrapper {
        margin-left: 10px;
        margin-top: 0;
        &.active {
            .block-minicart {
                .block-title {
                    font-size: 0.8rem;
                }

                &:before {
                    right: 6px;
                }

                &:after {
                    right: 5px;
                }

                .actions {
                    .primary {
                        #top-cart-btn-checkout {
                            width: 45%;
                            padding: 0.4rem 0 0.2rem;
                            line-height: 20px;
                            font-size: 25px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
