.aw-sarp2-product-subscription-options {
    .subscribe-and-save {
        display: inline-block;
    }

    .aw-sarp2-subscription__dropdown-switcher {
        margin-bottom: 10px;
    }

    .aw-sarp2-subscription__wrapper-options {
        margin-bottom: 10px;
    }

    .aw-sarp2-subscription__options-list {
        appearance: auto !important;
    }

    .block.block-title {
        margin: 0;
    }
}

.aw-sarp2-customer-subscriptions {
    .products {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                margin: 0;
            }
        }
    }
}

.aw_sarp2-profile_edit-plan {
    .product-items {
        margin: 0;
        padding: 0;
    }

    .product-item {
        display: block;
    }
}

.aw_sarp2-profile_edit-nextpaymentdate {
    .aw-sarp2__next-payment-date-form {
        .next-payment-date {
            display: flex;
            flex-wrap: wrap;

            label[for='next-payment-date'] {
                width: 100%;
                padding: 0;
                margin-bottom: 10px;
                text-align: left;
            }

            .control.next-payment-date {
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
            }

            .ui-datepicker-trigger {
                width: 32px;
                height: 32px;

                &::before {
                    width: 32px;
                    height: 32px;
                    font-size: 32px;
                    line-height: 1;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .table-wrapper.aw-sarp2-customer-subscriptions {
        td {
            margin: 0;
        }
    }

    .aw-sarp2-product-subscription-options {
        .fieldset {
            padding: 15px 0 1px;
        }
    }

    .page-title-wrapper {
        display: none;
    }

    #my-subscriptions-table {
        margin-top: 40px;
    }

    .aw_sarp2-profile_edit-index {
        .aw-sarp2-customer-subscriptions {
            &.table-wrapper {
                padding: 20px 0;

                table.table {
                    & > tbody {
                        & > tr {
                            & > td,
                            th {
                                &::before {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
