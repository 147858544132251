section {
  &.center {
    text-align: center;

    p, li {
      text-align: center;
    }
  }

  &.container {
    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    overflow-x: hidden;
      &.hp-fullwidth {
          padding-right: 0;
          padding-left: 0;
      }

    .row {
      box-sizing: border-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -8px;
      margin-left: -8px;

      img {
        width: 100%;
      }
    }

    .col-xs-12 {
      width: 100%;
    }
  }

  picture img {
    width: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .headline {
    border-bottom: 4px solid #4b5699;
    padding-bottom: 1rem;
    font-size: 32px;
    font-weight: 500;
    display: inline-block;
  }

  .petworld-headline {
    padding-bottom: 20px;
  }

  &.club-banner {
    position: relative;

    .button-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -25px -129px;
    }

    .action.primary {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 1.1px;
    }
  }
}

.cms-page-view,
.cms-index-index {
  p, li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #474445;
  }

  .footer {
    p, li {
      color: #ffffff;
    }
  }
}
.store-petxl {
  &.cms-page-view,
  &.cms-index-index {
    .footer {
      p, li {
        color: #474445;
      }
    }
  }
}

.page-main h1,
.page-main h2,
.page-main h3 {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.hp-3col.container,
.hp-2col.container {
  padding-right: 15px;
  padding-left: 15px;
}

.widget .block-title {
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;

  strong {
    font-size: 32px;
    font-weight: 700;
    border-bottom: 4px solid #4b5699;
    padding-bottom: 1rem;
    display: inline-block;
  }
}

.store-petxl {
  .widget .block-title {
    strong {
      border-bottom: 4px solid #f7921e;
      font-size: 24px;
      color: #f7921e;
    }
  }
}

.cms-page-view {
  .kunde-grid {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;

    .kunde-item {
      width: 33.3333%;
      padding: 10px;
      margin: 0;
      text-align: center;

      a {
        display: inline-block;
        vertical-align: top;
        background: #353d69;
        box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, .75);
        backface-visibility: hidden;
        transform: translateZ(0);
        transition-property: transform, -webkit-transform;
        transition-duration: .3s;

        &:hover {
          transform: scale(1.05);
        }
      }

      .kundeicon {
        text-align: center;
        padding: 2.5rem 8rem 0;
        display: block;
      }

      .kundetitle {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        font-weight: 700;
        font-size: 26px !important;
        color: #fff;
        padding-bottom: 3rem;
        text-transform: uppercase;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        line-height: 1.1;
      }
    }
  }

  .page-main {
    .choose-us {
      background: #f9f9f9;
      padding: 20px 0;
      margin-bottom: 20px;

      h2 {
        margin-top: 0;
      }
    }

    .delivery {
      background: #f6f2ea;
      padding: 30px 40px;
      margin-bottom: 20px;

      h2 {
        margin-top: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #474445;
      }
    }

    .price-match {
      background: #f6f2ea;
      padding: 30px;
      margin-bottom: 20px;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;

      h2 {
        margin-top: 0;
      }

      .content {
        width: 65%;
        padding-right: 5%;
      }

      .imgcontainer {
        width: 35%;
      }
    }

    .return {
      background: #f6f2ea;
      padding: 30px 40px;
      margin-bottom: 20px;

      h2 {
        margin-top: 0;
      }
    }

    .newsletter-page {
      padding-top: 30px;

      input {
        padding: 7px 0 7px 35px;
        height: 44px;
      }

      .actions {
        padding: 20px 0 0;

        .action.subscribe {
          padding: 7px 20px;
          white-space: nowrap;
          vertical-align: bottom;
          font-size: 16px;
          height: 44px;
        }
      }
    }
  }

  .testimonials {
    padding: 20px;
    background: #4b5798;
    color: #fff;
    overflow: hidden;

    ol {
      padding: 0;
      list-style: none;

      > li {
        color: #fff;
        padding: 0 0 30px;
      }
    }

    .col1 {
      float: left;
      width: 30%;
    }

    .col2 {
      float: right;
      width: 30%;
    }

    .sumtext {
      overflow: hidden;
      padding: 0 20px;
      font-size: 18px;
      line-height: 22px;

      .img-holder {
        text-align: center;
        padding-bottom: 20px;
      }

      img {
        max-width: 200px;
      }

      p {
        color: #ffffff;
      }
    }
  }
}

.page-layout-1column .block.widget {
  padding: 0;

  .products-grid .product-item {
    width: 25%;
    padding: 0 5px;

    .category-item-photo {
      text-align: center;
    }

    .usp-list-product {
      display: none;
    }

    .product-item-link__button {
      display: none;
    }

    .product-item-name {
      text-align: center;
    }

    > a:hover {
      color: #4b5699;
    }
  }
}

.store-petxl.page-layout-1column .block.widget .products-grid .product-item > a:hover{
  color: #f7921e;
}


@media screen and (max-width: 767px) {
  .widget .block-title strong {
    font-size: 20px;
  }
  section .headline {
    font-size: 20px;
  }
  .vc_hidden-xs {
    display: none !important;
  }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 50%;
  }
  .cms-page-view {
    .kunde-grid {
      .kunde-item {
        width: 100%;

        .kundeicon {
          padding: 2.5rem 6rem 0;
        }
      }
    }

    .page-main .price-match {

      .content {
        width: 100%;
        padding: 0 0 30px;
      }

      .imgcontainer {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .testimonials {
      .col1, .col2 {
        float: none;
        width: auto;
      }

      .sumtext {
        padding: 0;
      }
    }

  }
  .hp-3col.container, .hp-2col.container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .vc_hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  section {
    .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: 8px;
    }

    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 8px;
    }
  }

}
