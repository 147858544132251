*{
    font-family: 'Open Sans', 'Arial', sans-serif;
}

#cookie-status {
    display: none;
}

/* classes for grid */
.p-full{
    width: 100%;
}
.p-half{
    width: 50%;
}

/* breadcrumbs */
.page-wrapper{
    .breadcrumbs{
        margin: 0.5rem auto 0;
    }
}

/* weird amasty subscribe placement below footer on search page */
.amxnotif-block.subscribe-popup{
    display: none;
}

/* for Kundeservice Zendesk popup */
#webWidget{
    z-index: 2;
}

#maincontent{
    .columns{
        margin-top: 0;
    }
}
/* Modal window*/
.modal-popup .modal-inner-wrap {
    max-width: 600px;
}
.cms-index-index {
    .page-main {
        max-width: none;
        padding: 0 !important;
    }
}

.modals-wrapper {
    position: relative;
    z-index: 111111;
}

@include max-screen($screen__m){
    body{
        .page-wrapper{
            .page-main{
                padding-top: 0;
            }
            .breadcrumbs{
                margin: 1rem;
            }
        }
        .desktop-404 {
            display: none;
            a {
                margin: 0;
                padding: 0;
                display: flex;
                cursor: pointer;
            }
        }
    }
    .cms-no-route {
        .column.main {
            padding: 0;
        }
        #maincontent {
            padding: 0;
            margin: 0;
        }
        .page-footer {
            margin-top: 0;
        }
    }
}
@include min-screen($screen__l){
    .mobile-404 {
        display: none;
    }
    .cms-no-route {
        .column.main {
            padding: 0;
        }
        #maincontent {
            margin-bottom: 0;
        }
    }
}
//
//  404 page styling
//  _________________________
.wrong-page {
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        color: #fff;
        align-items: center;
        height: 100%;
        h1 {
            font-weight: 900;
            font-size: 80px;
        }
        h3 {
            font-size: 30px;
            font-weight: 900;
            margin-top: 2rem;
        }
        a {
             padding: 1rem;
             text-transform: uppercase;
             margin-top: 4rem;
             border-radius: 4px;
             box-shadow:  0 3px 5px 0 #b34b0b;
             background-color: $petworld-button__background-color;
             color: #fff;
             font-size: 25px;
             font-weight: 900;
             letter-spacing: 1px;
             &:hover {
                 background-color: darken($petworld-button__background-color, 6%);
                 text-decoration: none;
             }
        }
    }
}
@include screen($screen__m, $screen__l){
    .mobile-404.wrong-page {
        display: none;
    }
    .desktop-404.wrong-page .overlay {
        h1 {
            font-size: 60px;
        }
        a {
            margin-top: 2rem;
            font-size: 20px;
        }
    }
}
@include max-screen($screen__m){
    .wrong-page {
        .overlay {
            h1 {
                font-size: 60px;
            }
            h3 {
                margin-top: 1rem;
                line-height: 30px;
                font-size: 26px;
            }
            a {
                margin-top: 2rem;
                font-size: 20px;
            }
        }
    }
}
