// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$account-title-border-color: $color-gray-middle2;
$account-table-border-bottom-color: $color-gray-middle1;
$account-table-action-delete: $color-red12;

$_password-default: $color-gray-light01;
$_password-weak: #ffafae;
$_password-medium: #ffd6b3;
$_password-strong: #c5eeac;
$_password-very-strong: #81b562;


//
//  Common
//  _____________________________________________

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            font-size: 18px;
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);

        &:after {
            margin-top: 35px;
        }
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .item.actions {
            margin-top: $indent__xs;

            .action {
                &:after {
                    border-left: 1px solid $primary__color__light;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin: 0 $indent__s;
                    vertical-align: -1px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

    }

    address {
        line-height: 24px;
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.form-create-account {
    .fieldset-fullname {
        .fields {
            .field {
                float: none;
            }
        }
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 24px;
    }
}

//  My account
.account {
    .page-title-wrapper {
        .page-title {
            display: inline-block;
        }
    }

    .messages {
        margin-bottom: $indent__base;
    }

    .column.main {
        margin-bottom: $indent__l;

        h2 {
            margin-top: 0;
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: $indent__xl;
    }

    .table-wrapper {
        margin-bottom: $indent__base;
        border-bottom: 1px solid $account-table-border-bottom-color;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .legend {
        > strong,
        > span {
            font-size: 22px;
            font-weight: $font-weight__light;
        }

        border-bottom: 1px solid $account-title-border-color;
        margin-bottom: $indent__m;
        padding-bottom: $indent__s;
    }
}

.account,
[class^='sales-guest-'],
.sales-guest-view {
    .column.main {
        .order-details-items {
            .table-wrapper {
                .data.table {
                    @include lib-table-striped(
                        $_stripped-highlight: even
                    );
                    @include lib-table-bordered(
                        $_table_type: light
                    );
                }
            }
        }
    }

    .data.table {
        .col.actions {
            .action {
                &:after {
                    border-left: 1px solid $primary__color__light;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin: 0 $indent__s;
                    vertical-align: -1px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                &.delete {                           
                    color: $account-table-action-delete;
                }                                    
            }                                        
        }                                            
    }                                                
}                                                    
                                                     
//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;
    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        strong {
            @include lib-heading(h4);
            .column.main & {
                //
            }
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }

    .box-actions {
        margin-top: $indent__xs;
        .action {
            &:after {
                border-left: 1px solid $primary__color__light;
                content: '';
                display: inline-block;
                height: 12px;
                margin: 0 $indent__s;
                vertical-align: -1px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        display: flex;
        flex-direction: column; // Check this value
        //@include lib-vendor-prefix-flex-direction(2);

        .mage-error {
            order: 2;
        }

        .input-text {
            order: 0;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-strength-meter-0 & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-strength-meter-1 & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-strength-meter-2 & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strength-meter-3 & {
        
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-strength-meter-4 & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    margin-top: $indent__s;

    .captcha-img {
        vertical-align: middle;
    }
}

.account {
    .data.table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

.order-products-toolbar {
    position: relative;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    .form-create-account {
        .fieldset-fullname {
            .fields {
                .field {
                    float: left;
                    margin: 0 $indent__s $indent__s 0;
                }
            }

            .field-name-prefix,
            .field-name-suffix {
                width: 50px;
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //  My account
    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .page-title-wrapper {
            @extend .abs-add-clearfix-desktop;
        }

        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .data.table {
            margin-bottom: 0;

            .col {
                &.actions {
                    white-space: nowrap;
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;

            > .item {
                @extend .abs-blocks-2columns;
                margin-bottom: $indent__base;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }

        address {
            font-size: 16px;
        }
    }

    .page-main {
        .block {
            margin-bottom: $indent__xl + $indent__s;
        }
    }

    .form-address-edit {
        .fieldset {
            @extend .abs-blocks-2columns;
        }
    }

    .form-edit-account {
        .fieldset {
            @extend .abs-blocks-2columns;

            .fieldset {
                margin-bottom: $indent__base;
                width: 100%;
            }
        }
    }

    .box-billing-address,
    .box-shipping-address,
    .box-information {
        .box-content {
            font-size: 16px;
        }
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

//
//  Mobile $screen__s
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }

        .data.table {
            > tbody > tr > td:last-child {
                border: 0;
           }
        }
    }
}

//
//  Mobile $screen__m
//  _____________________________________________

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .column.main {
            .block:not(.widget) {
                .block-title {
                    @extend .abs-add-clearfix-mobile-m;

                    > .action {
                        float: right;
                        margin-top: $indent__xs;
                    }
                }

                .block-content {
                    .box {
                        margin-bottom: $indent__base;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .control.captcha-image {
        .captcha-img {
            display: block;
            margin-bottom: $indent__s;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}
