// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$collapsible-nav-background: $sidebar__background-color !default;
$collapsible-nav-color: $color-gray34 !default;
$collapsible-nav-current-border: 3px solid transparent !default;
$collapsible-nav-current-border-color: $color-orange-red1 !default;
$collapsible-nav-current-color: $color-black !default;
$collapsible-nav-current-font-weight: $font-weight__semibold !default;
$collapsible-nav-item-hover: $color-gray91 !default;

//
//  Common
//  _____________________________________________

//
//  Collapsible navigation
//  -----------------------------------------

.block-collapsible-nav {
    .content {
        background: $collapsible-nav-background;
        padding: 15px 0;
    }
    .item {
        margin: 3px 0 0;
        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            border-left: 3px solid transparent;
            color: $collapsible-nav-color;
            display: block;
            padding: 5px 18px 5px 15px;
        }

        a {
            text-decoration: none;

            &:hover {
                background: $collapsible-nav-item-hover;
            }
        }

        &.current {
            a,
            > strong {
                border-color: $collapsible-nav-current-border-color;
                color: $collapsible-nav-current-color;
                font-weight: $collapsible-nav-current-font-weight;
            }

            a {
                border-color: $collapsible-nav-current-border-color;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-collapsible-nav {
        .title {
            @extend .abs-visually-hidden-desktop;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-collapsible-nav {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        width: 100%;
        background: #ffffff;

        .title {
            @extend .abs-toggling-title-mobile;
        }

        .content {
            border-bottom: $border-width__base solid $border-color__base;
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
