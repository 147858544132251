/* TABLET STYLING  */
@include screen($screen__s, $screen__l){
    .cms-home {
        .petworld-headline .wpc-headline {
            font-size: 22px;
        }
        .petworld-small .wpcloud-banner2__button-wrapper .wpcloud-banner2__button {
            padding: 1.2rem;
        }
    }
}

@include min-screen($screen__l) {
    .cms-home {
        .slick-arrow {
            &.slick-prev {
                margin-left: 25px;
                margin-right: 0;
                box-shadow: none;
                width: 40px;
                height: 40px;
                background: $petworld-blue__color;
                border-bottom: 2px solid darken($petworld-blue__color, 5%);
                border-radius: 50%;
                &:before {
                    @include material-icon($icon-arrow-left-thin);
                }
            }
            &.slick-next {
                margin-right: 20px;
                margin-left: 0;
                box-shadow: none;
                width: 40px;
                height: 40px;
                background: $petworld-blue__color;
                border-bottom: 2px solid darken($petworld-blue__color, 5%);
                border-radius: 50%;
                &:before {
                    @include material-icon($icon-arrow-right-thin);
                }
            }
        }
    }
}
