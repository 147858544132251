.slider-grid-widget {

  // General
  .owl-carousel.additional-carousel-products.pdp {
    padding: 0 10px;

    .product.product-item {
      width: 100%;
      padding: 1px 5px;

      .product-item-link {
        font-size: 16px;
      }

      .price-box .price {
        font-size: 18px !important;
      }

      .usp-list-product {
        display: flex;
      }

      .product-item-link__button {
        display: block;
      }
    }
  }

  // Upsell only
  &.upsell {
    .block-title strong {
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid #4b5699;
      padding-bottom: 10px;
      display: inline-block;
      margin-bottom: 5px;
    }

    .owl-carousel.additional-carousel-products.pdp {
      padding: 0 10px;

      .product.product-item {
        padding: 1px;
        box-sizing: border-box;

        .product-item-info {
          padding: 10px 5px;
        }

        .product-item-link__button .btn-list {
          display: block;
          padding: 10px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .products-slider {
    .additional-carousel-products.pdp {
      margin: 0;
      &.product-items {
        .product-item {
          &-info {
            box-shadow: none;
          }
        }
      }
    }
  }
}