// Mixins
@import "mixins/media-queries";

// Magento UI Overrides
@import 'extensions/import-before-ui';
@import 'color-palette';
@import 'icon-overrides';
@import 'ui-variable-overrides';

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI
@import 'vendor/polarcore-ui/lib'; // PolarCore UI
@import 'vendor/material-design/icons'; // Material Design Icons

// Theme variables
@import 'variable-overrides';
@import 'variables';

// Reset default styles with magento-reset
@include lib-magento-reset();

/* Gridlex */
@import "vendor/gridlex/polarcore-vars";
@import "vendor/gridlex/gridlex-vars";
@import "vendor/gridlex/gridlex-preprocessing";
@import "vendor/gridlex/gridlex-mixins";
@import "vendor/gridlex/gridlex-classes";

// Theme blocks
@import 'blocks/extends';
@import 'blocks/luma-extends';
@import 'blocks/typography';
@import 'blocks/layout';
@import 'blocks/tables';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/tooltips';
@import 'blocks/loaders';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/buttons';
@import 'blocks/sections';
@import 'blocks/pages'; // Theme pager
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/popups';
@import 'blocks/price';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_AdvancedSearch/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_BraintreeTwo/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';


// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// Third-party
@import 'extensions/slick';

// PolarCore 2 Extensions
@import 'extensions/polarcore-resets';
@import 'extensions/polarcore-general';
@import 'extensions/polarcore-modal';
@import 'extensions/polarcore-layouts';
@import 'extensions/polarcore-mobile-flex-header';
@import 'extensions/polarcore-rating';
@import 'extensions/polarcore-listings';
@import 'extensions/polarcore-layered-navigation';
@import 'extensions/polarcore-product-view';
@import 'extensions/polarcore-footer';
@import 'extensions/polarcore-wishlist';
@import 'extensions/polarcore-header';
@import 'extensions/polarcore-typography';
@import 'extensions/import';

@import 'te/import';

@import 'amasty/module';

@import 'blog/blog';
