
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    header .header {
        &.content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            padding: 0.5rem !important;
            min-height: 110px;
            position: sticky;
            top: 0;
            z-index: 900;
            & > .nav-toggle {
                position: static;
                padding: 0;
                margin: 0;
            }
            & > .minicart-wrapper {
                position: static;
                padding: 0;
                margin: 0;
            }
            & > .logo {
                margin: 0.5rem auto;
                opacity: 1;
                transition: all 400ms ease;
            }
            & > .polarcore-header-links {
                position: absolute;
                right: 1rem;
            }
            & > .block.block-search {
                width: 100%;;
                margin-top: 0.5rem;
                order: 25;
                transition: all 300ms ease;
                position: absolute;
                top: 55px;
                background: white;
                margin: 5px 0;
                .control {
                    border-width: 0;
                }
                .field.search {
                    position: relative;
                    .label {
                        top: -0.5rem;
                    }
                }
            }
        }
    }

    header .nav-toggle:before {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: $polarcore-nav-toggle-special__background;
        box-shadow: 0 0px 0 4px $polarcore-nav-toggle-special__background;
    }

}

/* General fixed position styling */
header .header{
    &.content{
        &.fixed {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.32);
            padding: 0.5rem;
            min-height: 65px;
            background-color: #ffffff;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            position: fixed;
            & > .block.block-search {
                background: #fff;
                margin: 0 auto;
                padding: 0;
                z-index: 99;
            }
        }
    }
}

/* Desktop */
@include min-screen($screen__l + 1){
    header .header{
        &.content{
            &.fixed{
                position: unset;
                box-shadow: none;
                padding: 1.5rem 1rem;
            }
        }
    }
}

/* Tablet only */
@include screen($screen__m, $screen__l){
    header .header{
        &.content{
            &.fixed{
                .account-link{
                    display: block;
                    opacity: 1 !important;
                    margin-top: 12px;
                    margin-right: 10px;
                }
                .minicart-wrapper{
                    margin-top: 0;
                    margin-left: 13px;
                }
            }
        }
    }
}

/* Mobile & Tablet */
@include max-screen ($screen__l){
    header .header{
        &.content{
            & > .polarcore-header-links{
                top: 1.25rem;
            }
            &.fixed {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.32);
				padding: 0.5rem;
				min-height: 65px;
				background-color: #ffffff;
				top: 0;
				left: 0;
				width: 100%;
                z-index: 999;
                position: fixed;
                & > .block.block-search {
                    width: 60%;
                    top: 13px;
                    position: absolute;
                    background: #fff;
                    margin: 0 auto;
                    padding: 0;
                    z-index: 99;
                }
                .logo{
                    opacity: 0;
                }
                .account-link{
                    opacity: 0;
                }

            }
        }
    }
}

@include screen ($screen__m, $screen__l){
    header .header{
        &.content{
            & > .polarcore-header-links{
                top: 1rem;
            }
        }
    }
}
