

.page-layout-1column, .page-products{
    #maincontent{
        padding-top: 0;
        #amasty-shopby-product-list{
            .toolbar-products{
                .toolbar-amount{
                    display: block !important;
                }
                .sorter{
                    &-label{
                        display: inline-block;
						margin-right: 0.5rem;
                    }
                    #sorter{
                        width: auto;
                        background-color: #fff;
                        //-webkit-appearance: menulist !important;
                        border-radius: 0px;
                        margin: 0;
                        border-bottom: 3px solid #b5b5b5;
                        font-size: 14px;
                        color: $petworld-dark-grey__color;
                        height: 45px;
                        font-weight: 400;
                        border: 1px solid #c2c2c2;
                        border-bottom: 3px solid darken(#c2c2c2, 12%);
                        margin-right: 0.5rem;
                        text-align: left;
                        text-align-last: left;
                        font-size: 15px;
                        padding: 0rem 1rem;
                        cursor: pointer;
						display: inline-block;
                        &:hover{
                            color: #000;
                        }
                    }
                }
            }
        }
        #layered-filter-block{
            border-top: none;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            max-width: 85%;
            .filter-actions{
                display: none;
            }
            .filter-current{
                display: inline-flex;
                align-items: center;
                &-subtitle{
                    color: $petworld-dark-grey__color;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 14px;
                }
                .items{
					display: inline-flex;
					padding: 5px 10px;
                    .am-shopby-item{
                        display: flex;
						margin-right: 0.5rem;
						border-radius: 15px;
                        min-height: 30px;
						align-items: center;
						position: relative;
						z-index: 1;
						background: #e6e6e6;
                        width: fit-content;
                        .filter-value{
                            padding: 0.2rem;
                            color: #474747;
                            font-size: 14px;
                            .swatch-option{
                                margin-right: 5px;
                            }
                            .am-swatch-link{
                                display: flex;
                                align-items: center;
                            }
                        }
                        .action.remove{
                            z-index: 1;
                            margin-left: 2rem;
                            padding: 0.19rem 0.25rem 0.25rem 0.35rem;
                            display: inline-block;
                            position: unset;
                            &:before{
                                margin-left: 1rem;
                                order: 1;
                                position: absolute;
                                right: 8px;
                                top: 6px;
                                margin: 0;
                                text-align: center;
                                background-color: #474747;
                                border-radius: 50%;
                                padding: 0.25rem;
                                color: white;
                                content: '\E5CD';
                                font-weight: 900;
                                font-size: 13px;
                                text-indent: unset;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .amscroll-navbar{
        display: none;
        bottom: 2%;
        right: 14%;
    }
}

/* DESKTOP */
@include min-screen ($screen__m){
    .page-layout-1column,
    .page-products{
        .columns{
            .column.main{
                padding-top: 0;
                /* Toolbar Sorter positioning */
                #amasty-shopby-product-list{
                    .toolbar-products{
						.sorter{
                            margin-top: 0;
							#sorter{
                                height: 45px;
								margin-right: 1.5rem;
                                font-size: 15px;
                                box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
							}
                            &-action{
                                display: none;
                            }
						}
                        &:last-of-type{
                            display: none;
                        }
                    }
                }
            }
        }
        .filter-options{
            margin-top: 0;
            &-item{
                margin-bottom: 0;
                width: 172px;
                background-color: white;
                border-radius: 0px;
                margin: 0;
                border-bottom: 3px solid #b5b5b5;
                //-webkit-appearance: menulist !important;
                font-size: 12px;
                font-weight: 400;
                color: #1a1a1a;
                border: 1px solid #c2c2c2;
                margin-right: 1.5rem;
                margin-bottom: 1.5rem;
                padding-bottom: 0;
                box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
                z-index: 0;
                &.active, :hover{
                    z-index: 2;
                }
            }
            &-title{
                padding: 0.75rem 2rem 0.75rem 1rem;
                font-size: 15px;
                text-transform: initial;
                &:after{
                    font-size: 25px;
                    //display: none;
                }
            }
            &-content{
                width: 250px;
                box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
                input{
                    cursor: pointer;
                }
                .item{
                    padding: 0.25rem 0 !important;
                }
                .item.swatch-option-link-layered{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 6px 0 !important;
                }
                .items-children{
                    .item{
                        padding: 5px 5px 0px;
                        margin: 5px 0px 2px !important;
                    }
                }
            }
        }
    }
}

/* MOBILE */
@include max-screen ($screen__m){
    .page-layout-1column,
    .page-products{

        .amscroll-navbar{
            right: 21%;
        }
        #maincontent{
            .p2-category-top{
                #layered-filter-block{
                    max-width: 100%;
                    .filter-current{
                        display: block;
                        &-subtitle{
                            margin-right: 1rem;
                        }
                        .items{
                            display: block;
                            .filter-label{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* TABLET STYLING  */
@include screen($screen__s, $screen__l){
.page-layout-1column #maincontent #layered-filter-block .filter-current,
.page-products #maincontent #layered-filter-block .filter-current
	.page-products,
	.page-layout-1column {
		#maincontent {
			#layered-filter-block {
				.filter-current {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					.items {
						display: flex;
						flex-wrap: wrap;
						.am-shopby-item {
							margin: 5px 0;
						}
					}
				}
			}
		}
	}
}
