//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                             : 18px !default;
$checkout-progress-bar__font-weight                           : $font-weight__light !default;
$checkout-progress-bar__margin                                : $indent__base !default;

$checkout-progress-bar-item__background-color                 : $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius                    : 6px !default;
$checkout-progress-bar-item__color                            : $primary__color !default;
$checkout-progress-bar-item__margin                           : $indent__s !default;
$checkout-progress-bar-item__width                            : 185px !default;
$checkout-progress-bar-item__active__background-color         : #555ea5;
$checkout-progress-bar-item__complete__color                  : $link__color !default;

$checkout-progress-bar-item-element__width                    : 48px !default;
$checkout-progress-bar-item-element__height                   : 48px !default;

$checkout-progress-bar-item-element-outer-radius__width       : 6px !default;
$checkout-progress-bar-item-element-inner__background-color   : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color              : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-inner__width              : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height             : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content    : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size  : 28px !default;
$checkout-progress-bar-item-element-inner__active__line-height: 1.5 !default;


//
//  Checkout Progress Bar
//  _____________________________________________

.opc-progress-bar {
    @extend .abs-reset-list;
    margin: 0 0 $checkout-progress-bar__margin;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    margin: 0 0 $checkout-progress-bar-item__margin;
    width: 50%;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    &:before { // Horizontal line
        background: $checkout-progress-bar-item__background-color;
        top: $checkout-progress-bar-item-element__width / 2;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:first-child {
		margin-right: 100px; 
        &:before {
            border-radius: $checkout-progress-bar-item__border-radius 0 0 $checkout-progress-bar-item__border-radius;
        }
		&:after {
			content: '';
			background: #000;
			width: calc(100% - -1rem);
			height: 1px;
			position: absolute;
			top: 20px;
			left: calc(100% - 3.5rem);
		}
    }

    &:last-child {
        &:before {
            border-radius: 0 $checkout-progress-bar-item__border-radius $checkout-progress-bar-item__border-radius 0;
        }
    }

    & > span {
        display: inline-block;
        padding-top: 55px;
        width: 100%;
        word-wrap: break-word;

        @include lib-typography(
            $_color      : $checkout-progress-bar-item__background-color,
            $_font-family: inherit,
            $_font-size  : $checkout-progress-bar__font-size,
            $_font-style : inherit,
            $_font-weight: $checkout-progress-bar__font-weight,
            $_line-height: 1.6 
        );

        &:before,
        &:after {
            background: #fff;
            height: $checkout-progress-bar-item-element__height;
            margin-left: -($checkout-progress-bar-item-element__width / 2);
            width: $checkout-progress-bar-item-element__width;
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
        }

		&:before {
			border: 1px solid $checkout-progress-bar-item__active__background-color;	
		}

        &:after {
            background: $checkout-progress-bar-item-element-inner__background-color;
            height: $checkout-progress-bar-item-element-inner__height;
            margin-left: (-$checkout-progress-bar-item-element-inner__width / 2);
            top: 10px;
            width: $checkout-progress-bar-item-element-inner__width;
            content: counter(i);
            counter-increment: i;
            @include lib-typography(
                $_color      : $checkout-progress-bar-item-element-inner__color,
                $_font-family: inherit,
                $_font-size  : $checkout-progress-bar__font-size,
                $_font-style : inherit,
                $_font-weight: $font-weight__semibold,
                $_line-height: inherit
            );
        }
    }

    &._active {
        &:before {
            background: $checkout-progress-bar-item__active__background-color;
        }

        & > span {
            color: $checkout-progress-bar-item__color;
            font-weight: 600;

            &:before {
                background: $checkout-progress-bar-item__active__background-color;
            }
			&:after {
				background: transparent;
				color: #fff;
			}
        }
    }

    &._complete {
        cursor: pointer;

        & > span {
            color: $checkout-progress-bar-item__color;

            &:after {
                content: $checkout-progress-bar-item-element-inner__active__content;
                font-family: $icons__font-name;
                line-height: $checkout-progress-bar-item-element-inner__active__line-height;
                font-size: $checkout-progress-bar-item-element-inner__active__font-size;
				top: 3px;
            }
        }
    }
}

.modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
    display: none !important;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-progress-bar-item {
        width: $checkout-progress-bar-item__width;
    }
}

