#ajaxcart {
    z-index: 1000000 !important;

    .inner{
        .button-close-top{
            color: #fff;
        }
        .success{
            background: $petworld-blue__color;
            color: #fff;
        }
        .product{
            .product-name{
                margin: 0.5rem 0;
                font-size: 16px;
                color: $petworld-cod-grey__color;
            }
        }
        .cartamount{
            text-align: center;
            background: #ffffff;
            border-bottom: 1px solid #e3e3e3;
            display: flex;
            padding: 1rem 1rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .remaining-amount{
                margin-bottom: 10px;
                color: $petworld-cod-grey__color;
                font-size: 14px;
                font-weight: 600;
            }
            .amount-percent{
                width: 400px;
                height: 12px;
                -webkit-appearance: none;
            }
            progress[value]::-webkit-progress-bar{
                border-radius: 6px;
                background-color: #ebebeb;
            }
            progress[value]::-webkit-progress-value{
                background: #40c353;
                border-radius: 6px;
            }
        }
        .actions{
            background: $petworld-light-grey__color;
            display: block;
            padding: 0.5rem 0 0.75rem;
            .button-wrapper{
                display: block;
                text-align: center;
                margin-bottom: 1.5rem;
                #button-close{
                    background: transparent;
                    border: none;
                    text-transform: uppercase;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                #button-checkout{
                    display: none;
                }
                #button-cart{
                    @include petworld-primary-button;
                    padding: 0.8rem 4rem !important;
                }
            }
        }
    }
}

#ajaxcart-loading{
    z-index: 1000000 !important;
}

@include max-screen ($screen__m){
    #ajaxcart{
        .inner{
            .cartamount{
                .amount-percent{
                    width: 320px;
                }
            }
        }
    }
}
