.checkout-index-index .checkout-success,
.checkout-onepage-success .checkout-success {
    width: 50%;
	height: 240px;
    text-align: center;
	.actions-toolbar {
		margin: 0;
		display: flex;
	}
	p {
		font-size: 18px;
	}
	a.order-number {
		color: #4e5bab;
		margin-left: 5px;
	}
}

@include max-screen($screen__m) {
	.checkout-index-index .checkout-success,
	.checkout-onepage-success .checkout-success {
		width: 100%;
		height: fit-content;
		margin: 0;
	}
}
