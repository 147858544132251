/*
*
* Common Product View Extensions
*
*/

.product-info-main {

    .product.attribute.manufacturer{
        font-size: 18px;
        margin: 0.25rem 0;
        color: $petworld-cod-grey__color;
    }
    .product-info-price {
        display: inline-block;
        width: 100%;
    }
    .price-label {
        display: none !important;
    }

    .product-reviews-summary {
        padding: 0.75rem 0;
    }

    .amshopby-option-link.brand{
        display: none;
    }

    .product.attibute.brand{
        float: right;
        margin-top: -1.8rem;
    }

    .prices-tier{
        display: none;
    }
    .price-box {
        display: inline-flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding: 1rem 0;
        font-family: 'Open Sans', 'Arial', sans-serif;
        .special-price{
            margin: 0;
            color: $petworld-special-price__color;
            font-size: 28px;
            font-weight: 700;
            line-height: 14px;
            .price{
                color: $petworld-special-price__color;
            }
        }
        .special-price.loyalty{
            color: $petworld-medlem-blue__color;
            font-size: 28px;
            font-weight: 700;
            line-height: 14px;
            .price{
                color: $petworld-medlem-blue__color;
            }
        }
        .regular-price{
            .price-label{
                display: none;
            }
        }
        .minimal-price-link{
            margin-top: 0;
            margin-left: 0.5rem;
            .price-label{
                display: none;
            }
            .price-wrapper{
                color: $petworld-medlem-blue__color;
                font-size: 28px;
                font-weight: 700;
            }
        }
        .old-price{
            color: $petworld-dark-grey__color;
            margin-right: 1rem;
            .price{
                font-weight: 400;
            }
        }
        .price{
            font-size: 28px !important;
        }
    }

    .registration-wrapper{
        display: inline-block;
        vertical-align: text-bottom;
        float: right;
        margin: 1rem 0;
        .btn{
            background: #fff;
            color: $petworld-medlem-blue__color;
            transition: all 200ms ease;
            padding-right: 0.5rem;
            border: 2px solid $petworld-medlem-blue__color;
            border-radius: 5px;
            box-shadow: none;
            span{
                font-size: 14px;
                font-weight: 700;
                display: flex;
                align-items: center;
                &:after{
                    content: "chevron_right";
                    font-family: "Material icons";
                    color: $petworld-medlem-blue__color;
                    font-size: 14px;
                    margin-left: 0.5rem;
                    transition: all 200ms ease;
                }
            }
            &:hover{
                color: #fff;
                background: $petworld-medlem-blue__color;
                span{
                    &:after{
                        color: #fff;
                    }
                }
            }
        }
    }

    .product.alert.price{
        display: none;
    }

    .product.alert.stock{
        @include petworld-primary-button;
        text-transform: unset;
        a{
            color: #fff;
        }
        &:hover{
            a{
                text-decoration: none;
            }
        }
    }

    .amxnotif-block{
        #form-validate-price{
            display: none;
        }
        #form-validate-stock{
            margin-top: 1.25rem;
            > label{
                font-size: 18px;
                color: $petworld-dark-grey__color;
                font-family: 'Open Sans', 'Arial', sans-serif;
            }
            .notification-container{
                display: flex;
                align-items: flex-end;
                margin-top: 1rem;
                .input-fields{
                    margin: 0;
                    width: 60%;
                    input{
                        height: 47px;
                    }
                }
                .actions-toolbar{
                    margin: 0;
                    padding-bottom: 0.2rem;
                    margin-left: 1rem;
                    width: 40%;
                    .primary{
                        width: 100%;
                        button{
                            width: 100%;
                            font-size: 18px;
                            padding: 0.97rem;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .bundle-actions {
        margin-top: 1.5rem;
    }
    .page-title-wrapper{
        max-width: 80%;
        display: inline;
        .page-title{
            font-size: 28px;
            color: $petworld-cod-grey__color;
            font-weight: 400;
            display: inline-block;
            margin-top: 0;
            max-width: 80%;
        }
        .amshopby-option-link{
            margin-top: -20px;
            float: right;
            margin-right: 0;
            .link-short-description{
                display: none;
            }
            img{
                max-width: unset;
                max-height: unset;
            }
        }
    }

}

.field.subscription {
    padding: 20px;
    border-radius: 5px;
    background: #edf1f8;
    h5 {
        color: #4c549b;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0;
    }
    p {
        margin-bottom: 10px;
    }
    ul {
        padding: 0 2rem;
        margin-bottom: 1.5rem;
    }
    select {
        height: 40px;
        -webkit-appearance: menulist !important;
    }
}


.swatch-attribute.manufacturer .swatch-option, .swatch-attribute.size .swatch-option {
    border: none;
    background: #59aee0;
    border-radius: 4px;
    padding: 7px;
    color: #fff;
    border-bottom: 2px solid #308ec5;
}

.swatch-attribute.manufacturer .swatch-option.selected, .swatch-attribute.size .swatch-option.selected {
    color: #fff;
    background: #ef873f;
    border: none;
    border-bottom: 2px solid #c85f10;
}


.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
    width: 100%;

    .input-text.qty {
        border-radius: $polarcore-product-input-text-qty__border-radius;
        height: 47px !important;
    }

    .fieldset {
        display: flex;
        width: 100%;
        align-items: flex-start;
    }

    .actions {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .field.qty {
        margin-bottom: 0;
    }

    .actions .action.primary.tocart {
        width: 100%;

        &+.vipps-express-checkout {
            margin-top: 15px;
        }

        span:before {
            display: none;
        }

    }


    .field.qty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: auto;

        .control {
            display: flex;
            padding-right: 1rem;
            button{
                background: #fff;
                border: 1px solid #c8c8c8;
                color: $petworld-cod-grey__color;
                font-size: 18px;
                padding: 0;
                width: 35px;
                border-radius: 0;
                box-shadow: none;
            }
            input{
                border-right: none;
                border-left: none;
                border-bottom: 1px solid #c8c8c8;
                border-top: 1px solid #c8c8c8;
                font-size: 20px;
                color: $petworld-cod-grey__color;
            }
        }

        .label {
            display: none;
        }

    }

}

.product-social-links {

    .action.towishlist {

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span:before {
            @include material-icon($icon-wishlist);
            font-size: 1em !important;
            transform: scale(1.5);
            padding-right: 0.5rem;
        }

    }

}

.actions .action.primary.tocart {

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        letter-spacing: 1px;
    }

    span:before {
        @include material-icon($icon-cart);
        font-size: 1em !important;
        transform: scale(1.75);
        padding-right: 0.5rem;
    }

}

.product.info.detailed {

    .data.item.title {
        margin: 0;
        border: 1px solid $border-color__base;

        .switch {
            position: relative;
            letter-spacing: 1px;
        }

        .switch:after {
            @include material-icon($icon-down);
            position: absolute;
            right: 1rem;
            top: 1rem;
            bottom: auto;
            left: auto;
            z-index: 10;
        }

        &.active {
            border-bottom: 0;
            .switch:after {
                transform: rotate(180deg);
            }
        }

    }

    .data.item.content {
        border-top: 0;
        margin-top: 2rem;
        flex-direction: column;
        .product-title{
            font-size: 22px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
    }

    .review-add,
    #product-review-container {
        padding: 1rem;
        width: 100%;
    }

}

.product-bottom-content {
    clear: both;
    display: flex;
    flex-direction: column;
}

.product.info.detailed{
    .data.item.content{
        .product-title {
            font-weight: 500;
        }
        .inner.show-more{
            max-height: 157px;
            overflow: hidden;
            position: relative;
            &:before{
                content: '';
				position: absolute;
				bottom: 0px;
				left: 0;
				right: 0;
				margin: 0;
				height: 70px;
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
            }
        }
		#show-more-description{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
            color: $petworld-cod-grey__color;
            text-transform: uppercase;
			background-color: #f0f0f0;
			font-size: 14px;
			text-align: center;
			width: 50%;
            letter-spacing: 1px;
			margin: 1rem auto;
			box-shadow: 0px 3px 0px 0px darken(#f0f0f0, 24%);
		}
    }
}

/*
*
* Tablet only
*
*/

@include screen($screen__m, $screen__l){
    .product-info-main{
        .page-title-wrapper{
            display: flex;
        }
        .registration-wrapper{
            float: none;
            margin-top: 0;
        }
        .box-tocart{
            .fieldset{
                .actions{
                    .tocart{
                        span{
                            font-size: 12px;
                            letter-spacing: 0;
                            &:before{
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-bottoeckout-cart-index-content{
        display: contents;
        &__left{
            display: contents;
            width: 100%;
            .product.info.detailed{
                margin-top: 1.5rem;
                .additional-attributes{
                    width: 50%;
                    margin: 0 auto;
                }
                .data.item.content{
                    padding-left: 6rem;
                    padding-right: 6rem;
                }
                .data.item.title{
                    margin-top: 2rem !important;
                }
            }
        }
    }
}


/*
*
* Tablet up
*
*/

@include min-screen($screen__m) {
    .product-bottom-content {
        flex-direction: row;

        &__left {
            width: 57%;
        }

        &__right {
            width: 43%;
        }
    }
    .product.info.detailed{
        .product.data.items{
            .data.item.content{
                .additional-attributes-wrapper{
                    .col{
                        font-size: 16px;
                    }
                }
            }
            .item.title{
                width: fit-content;
                order: -1;
                margin: 0 40px;
                border: none;
                &.active{
                    .switch{
                        box-shadow: inset 0 -3px $petworld-blue__color;
                    }
                }
                &:last-of-type{
                    margin: 0;
                }
                .switch{
                    background: #ffffff;
                    font-size: 18px;
                    color: $petworld-cod-grey__color;
                    font-weight: 700;
                    padding: 1rem 0rem;
                    text-transform: uppercase;
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}



/*
*
* Mobile only
*
*/

@include max-screen($screen__m) {
    .product-info-main{
        z-index: 9;
        .product{
            .page-title{
                max-width: 70%;
                font-size: 22px;
            }
        }

        .registration-wrapper{
            display: block;
            width: 100%;
            margin-left: 0;
        }

        .product.alert.stock{
            font-size: 15px;
            width: 100%;
            text-align: center;
        }

        .product-add-form{
            .box-tocart{
                margin-bottom: 0;
                .control{
                    button, input{
                        height: 40px !important;
                        width: 35px !important;
                    }
                }
                .tocart{
                    max-height: 37px !important;
                    padding: 0.75rem !important;
                    span{
                        font-size: 13px !important;
                        &:before{
                            display: none !important;
                        }
                    }
                }
            }
            .box-tocart.update{
                .field.qty{
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
    .product.info.detailed {
        margin-top: 2rem;
        #reviews {
            flex-direction: column;
        }
        .product.data.items{
            .item.title{
                border: none;
                border-bottom: 3px solid $petworld-blue__color;
                &.active{

                }
                .switch{
                    font-size: 18px;
                    text-transform: uppercase;
                    color: $petworld-cod-grey__color;
                    font-weight: 700;
                    letter-spacing: 1px;
                    padding-left: 0;
                }
            }
            #description{
                p,
                span,
                strong{
                    font-size: 14px;
                }
            }
            .item.content{
                margin: 0;
                padding: 1rem 0;
                .additional-attributes-wrapper{
                    margin-bottom: 0;
                    margin-top: 0.5rem;
                    .additional-attributes{
                        margin-bottom: 0;
                        .col{
                            font-size: 16px;
                            margin-bottom: 0;
                        }
                    }
                }
                .product-title{
                    font-size: 18px;
                    margin-top: 0.5rem;
                }
            }
        }
    }

}
@include screen($screen__m, $screen__l) {
    .product-info-main {
        .product.attibute.brand {
            float: unset;
            margin: 0;
            text-align: left;
        }
        .page-title-wrapper {
            max-width: 100%;
            .page-title {
                max-width: 100%;
                margin-top: -1.8rem;
                margin-bottom: 0;
            }
        }
        .price-box {
            margin-top: 0;
        }
    }
    .product-bottom-content {
        flex-direction: column;
        .product-bottom-content__left,
        .product-bottom-content__right {
            width: 100%;
        }
        .product.data.items {
            margin-top: 1rem;
        }
    }
}

@include screen(1024px, 1239px){
    .product-info-main {
        .product.attibute.brand {
            float: right;
            margin-top: -1.8rem;
        }
        .page-title-wrapper .page-title {
            margin-top: 0;
            max-width: 80%;
        }
        .registration-wrapper {
            width: 100%;
        }

    }
    .product-bottom-content {
        flex-direction: row;
        margin-top: 1rem;
        .product-bottom-content__left {
            width: 57%;
        }
        .product-bottom-content__right {
            width: 43%
        }
    }
    .product.info.detailed .product.data.items .item.title {
        margin: 0 20px;
    }
}

