.members-club-index{
    .page-title-wrapper{
		text-align: center;
        .page-title{
            color: #000;
			margin-bottom: 2rem;
			font-size: 30px;
			margin-top: 0.75rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			border-bottom: 4px solid $petworld-blue__color;
			padding-bottom: 1rem;
			display: inline-block;
        }
    }
	.products ~ .toolbar-products {
    	display: none !important;
    }
    #amasty-shopby-product-list{
		margin-top: 3rem;
        .toolbar{
            &-sorter{
                position: static !important;
            }
        }
        .product-item{
            width: 25%;
        }
    }
}

/* TABLET */
@include screen($screen__m, $screen__l){
    .members-club-index{
        #amasty-shopby-product-list{
            .product-item{
                width: 33.33%;
            }
        }
    }
}

/* MOBILE */
@include max-screen($screen__m){
    .members-club-index{
        .toolbar{
            &-amount{
                display: contents !important;
            }
        }
        #amasty-shopby-product-list{
            .product-item{
                width: 50%;
            }
        }
    }
}
