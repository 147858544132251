.toolbar select.sorter-options { 

    text-align: center; // FF
    text-align-last:center; // Chrome fix
}

/**
*
* Mobile
*
*/
@include max-screen($screen__m) {

    .filter {
        &-title {
            strong {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;            
            }
        }
    }

    .toolbar select.sorter-options { 
        height: 50px;            
        width: calc(100% - 3rem);
    }

}
