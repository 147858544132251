.category-description {
  line-height: 1.7em;
  font-size: 16px;

  h2 {
    margin: 20px 0 15px;
    font-size: 24px;
  }

  p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.7em;
  }
}

.category-visma-short-description {
  margin: -10px 0 20px 0;
  line-height: 1.7em;
  font-size: 16px;

  p {
    font-size: 15px;
    margin: 10px 0;
    line-height: 1.7em;
  }
}

.read-more-mobile {
  display: none;

  text-align: center;
  margin-top: -15px;

  label {
    font-size: 16px;
    border-bottom: 2px solid #4b5699;
    padding-bottom: 2px;
    cursor: pointer;
  }
}

.p2-category-top.info-container {
    flex-direction: column;

    #showmore {
        display: none !important;

        &:checked, &:target {
            ~ {
                .category-visma-short-description {

                    display: block !important;
                }

                .read-more-mobile {
                    display: none !important;
                }
            }
        }
    }
}

// fix for missing styling on wp products list

// desktop and up 
@include min-screen($screen__l){
    .trollweb-wpcloud-page-view .block-products-list .products-grid .product-items .product-item {
        width: 25%;
    }
}
// Tablet
@include max-screen($screen__l){
    .trollweb-wpcloud-page-view .block-products-list .products-grid .product-items {
        padding: 0;
        .product-item {
            width: 33.33%;
        }
    }
}
@include max-screen($screen__m){
    .trollweb-wpcloud-page-view .block-products-list .products-grid .product-items {
        padding: 0;
        .product-item {
            width: 50%;
            .product-item-details {
                padding: 0;
            }
        }
    }

    .read-more-mobile{
     display: block;
    }
    .category-visma-short-description {
        display: none;
    }
}
