/* Category styling */
.products-grid{
    .product-reviews-summary{
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        .rating-summary{
            text-align: center;
            position: inherit;
            height: 25px;
            left: -2px;
        }
    }
}
.product-reviews-summary{
    .rating-summary{
        left: -2px;
        #lipscore-rating{
            display: flex;
            .lipscore-rating-slider{
                margin-right: 0.75rem;
            }
            .lipscore-compact-rating-wrapper{
                display: none;
            }
        }
    }
}

/* MOBILE */
@include max-screen($screen__m){
    .products-grid{
        .product-reviews-summary{
            .rating-summary{
                .lipscore-rating-star{
                    width: 17px;
                }
            }
        }
    }
}
