.page-header{
    border-bottom: none;
    max-width: $layout__max-width-wrap;
    margin: 0 auto;
    width: 100%;
    .panel.wrapper{
        .panel.header{
            padding: 0;

            .polarcore-top-links{
                width: 100%;
                padding: 0 1rem;
                .widget.block.block-static-block{
                    margin: 0;
                    ul{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 auto;
                        padding: 0;
                        li{
                            list-style: none;
                            margin: 0;
                            a{
                                display: flex;
                                align-items: center;
                                color: $petworld-dark-grey__color;
                                i{
                                    margin-right: 0.5rem;
                                    list-style: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header.content{
        .polarcore-header-links{
            font-weight: 700;
            color: #4A45A6;
            text-transform: uppercase;

            .widget.block.block-static-block{
                margin: 0;
            }

            .wishlist-link{
                display: none;
            }
            .account-link{
                display: none;
            }
            .minicart-wrapper{
                .action.showcart{
                    position: relative;

                    &:before{
                        display: none;
                    }

                    .cart-icon__img {
                        display: block;
                        width: 23px;
                        height: 23px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);

                    }

                    .cart-text{
                        .text{
                            font-size: 12px;
                            color: #4A45A6;
                            margin-right: 0.5rem;
                            text-align: left;
                        }
                        .amount{
                            margin-right: 0.2rem;
                        }
                    }
                    .counter.qty{
                        background: $petworld-orange__color;
                        top: -0.9rem;
                        right: auto;
                        left: 50%;
                        font-size: 14px;
                    }
                }
            }

            .user-icon {
                position: relative;
                text-decoration: none;
                color: inherit;
                padding-top: 25px;
                font-size: 12px;

                .user-icon__img {
                    display: block;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    box-sizing: border-box;
                }
            }
        }
    }
}

/* DESKTOP */
@include min-screen($screen__l){
    .page-header{
        padding: 0 20px;
        .header.content{
            padding: 1rem 15px !important;
            .logo-container{
                width: 30%;
                text-align: left;
                display: flex;

                .logo {
                    max-width: none;
                    img{
                        width: auto;
                        height: 43px;
                    }
                }
            }
            .block.block-search{
                position: relative;
                width: 40%;
                z-index: 4;
                padding-left: 0;
                order: 0 !important;
                input{
                    margin: 0;
                    position: static;
                    max-height: 60px;
                    height: 44px;
                    border: 1px solid #595959;
                }
            }
            .polarcore-header-links{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                .minicart-wrapper{
                    .action.showcart{
                        padding-top: 25px;

                        .counter.qty{
                            min-width: 27px;
                            width: auto;
                            height: 27px;
                            border-radius: 14px;
                            padding: 0 6px;
                        }
                    }
                }
            }
        }
    }
}

/* MOBILE - tablet styling */
@include max-screen($screen__l + 1px){
    .page-header{
        .action.nav-toggle{
            display: none;
        }
        .panel.wrapper{
            display: none;
        }
        .header.content{
            .minicart-wrapper{
                .action.showcart{
                    .cart-text{
                        display: none;
                    }
                    .counter.qty{
                        width: 23px;
                        height: 23px;
                    }
                }
            }
            .logo-container{
                padding: 9px;
                max-width: 150px;
                .logo {
                    margin: 0;
                }
            }
        }
    }
}

/* Landscape tablet */
@include screen(1024px, 1239px){
    .page-header{
        .header.content{
            z-index: 999999999999;
            &.fixed {
                .polarcore-header-links {
                    top: 1.6rem !important;
                    right: 2rem;
                    position: absolute;
                }
            }
            padding: 1.5rem 1rem 1.5rem 5rem !important;
            position: sticky;
            top: 0;
            .logo {
                justify-content: center;
                img {
                    width: auto;
                    height: 40px;
                }
            }
            .account-link{
                i {
                    &:before {
                        height: 44px;
                        width: 44px;
                        font-size: 3.5rem !important;
                        display: flex;
                        justify-content: center;
                        margin-right: 10px;
                    }
                }
            }
            .block.block-search{
                width: 35% !important;
                margin-left: 5% !important;
            }
            .minicart-wrapper.active .block-minicart {
                right: 3px !important;
            }
        }
    }
    .meganav_wrapper div.meganav > ul.open-mobile {
        top: 87px !important;
    }
    .meganav_wrapper{
        #menu-button{
            top: 15px !important;
            left: 20px !important;
            z-index: 99999999999999 !important;
        }
    }
}

/* MOBILE STYLING  */
@include max-screen($screen__s){
    .page-header .header.content {
        .polarcore-header-links {
            .account-link {
                margin-left: 0;
                .account-text {
                    display: none;
                }
                .account-icon {
                    margin-top: 7px;
                    margin-right: 10px;
                    &:before {
                        font-size: 32px;
                    }
                }
            }
            .user-icon,
            .showcart {
                font-size: 0;
                line-height: 0;
                width: 25px;
                height: 25px;
                display: block;
                padding: 0;

                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}
/* TABLET STYLING  */
@include screen($screen__s, $screen__l){
    .page-header .header.content {
        .polarcore-header-links {
            .account-link {
                margin: -2px 0 0 0;
                .account-text {
                    display: none;
                }
                .account-icon {
                    &:before {
                        font-size: 29px;
                    }
                }
            }
            .user-icon,
            .showcart {
                font-size: 0;
                line-height: 0;
                width: 25px;
                height: 25px;
                display: block;
                padding: 0;

                img {
                    width: 25px;
                    height: 25px;
                }
            }
            .minicart-wrapper.active .block-minicart {
                right: -5px;
                &:before {
                    right: 10px;
                }
                &:after {
                    right: 9px;
                }
            }
        }
    }
}
