// custom styling sccunt
.login-container {
    display: flex;
    .block-customer-login, .block-new-customer {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
        margin: 0 1rem;
        .block-title {
            background-color: #ebebeb;
            border-bottom: 0;
            padding: 0.5rem 1rem;
        }
        .block-content {
            padding: 1rem;
        }
    }
}
@include max-screen($screen__m){
    .login-container {
        flex-direction: column;
        .block-customer-login, .block-new-customer {
            margin: 1rem 0;
        }
    }
}
// forgot password design
.form.password.forget {
    .fieldset {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
        margin: 1rem;
        .field {
            padding: 1rem;
        }
        .field.note {
            background-color: #ebebeb;
            padding: 0.5rem 1rem;
        }
    }
}
// create account
.customer-account-create{
    .page-title-wrapper{
        text-align: center;
    }
}
.create.account.form-create-account {
    margin: 0 auto;
    .fieldset.create {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
        margin: 1rem 0;
        .legend {
            width: 100%;
            background-color: #ebebeb;
            border-bottom: 0;
            padding: 0.5rem 1rem;
            span {
                display: flex;
                align-items: center;
                &:before {
                    content: "1";
                    border: 1px solid grey;
                    border-radius: 50%;
                    width: 23px;
                    height: 23px;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    align-items: center;
                    margin-right: 0.5rem;
                }
            }
        }
        .field {
            padding: 0 1rem;
            .label {
                width: 100%;
                padding-bottom: 6px;
                text-align: left;
            }
            .control {
                width: 100%;
            }
        }
        &.info {
            .field-name-lastname {
                margin-bottom: 1rem;
            }
        }
        &.account {
            .legend span {
                &:before {
                    content: "2";
                }
            }
            .newsletter {
                background-color: #f5f8ff;
                margin: 0 1rem;
                padding: 1rem;
                display: block;
                &:before {
                    display: none;
                }
                .extra{
				    display: block;
					margin-left: 2.9rem;
					margin-top: 0.25rem;
					font-size: 14px;
					width: 90%;
                }
                .checkbox {
                    position: absolute;
                    opacity: 0;
                    & + label {
                        position: relative;
                        cursor: pointer;
                        padding: 0;
                        span{
                            font-size: 16px;
                        }
                    }
                    & + label:before {
                        content: '';
                        margin-right: 1rem;
                        display: inline-block;
                        vertical-align: text-top;
                        width: 25px;
                        height: 25px;
                        border: 2px solid #595959;
                    }
                    &:focus + label:before {
                        box-shadow: 0;
                    }
					&:checked + label:after {
						content: '';
						position: absolute;
						left: 7px;
						top: 11px;
						background: white;
						width: 2px;
						height: 2px;
						box-shadow:
						  2px 0 0 white,
						  4px 0 0 white,
						  4px -2px 0 white,
						  4px -4px 0 white,
						  4px -6px 0 white,
						  4px -8px 0 white;
						transform: rotate(45deg);
					}
                    &:checked + label:before {
                        background: #595959;
                    }
                }
                .label {
                    padding: 0;
                    display: flex;
					.text {
                        display: flex;
                        flex-direction: column;
                        .header {
                            font-size: 16px;
                        }
                    }
                }
            }
            .actions-toolbar {
                margin: 1rem;
                .primary {
                    float: none;
                    display: flex;
                    .action.submit.primary {
                        width: 100%;
                        justify-content: center;
                        margin: 0;
                    }
                }
            }
        }
    }
}
// account page styling
.account {
    .sidebar-main {
        #block-collapsible-nav {
            background-color: #fff;
            box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
        }
    }
    .columns .column.main {
        .page-title-wrapper {
            margin-bottom: 1rem;
        }
        .block {
            box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
            margin-bottom: 1rem;
            .block-title {
                padding: 0.5rem 1rem;
                border-bottom: 0;
                background-color: #ebebeb;
            }
            .block-content {
                padding: 1rem;
            }
        }
        form .fieldset {
            box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
            margin-bottom: 1rem;
            .note {
                padding: 0 1rem;
            }
            .legend {
                padding: 0.5rem 1rem;
                width: 100%;
                border-bottom: 0;
                background-color: #ebebeb;
            }
            .field {
                padding: 0 1rem;
                &.company,
                &.region,
                &.additional {
                    display: none;
                }
                &:before {
                    width: auto;
                }
                &:last-of-type {
                    margin-bottom: 29px;
                }
                label[for="member_of_club"]{
                    font-size: 16px;
                }
                .extra{
                    display: block;
					margin-left: 3.5rem;
					margin-top: 0.5rem;
					font-size: 14px;
					width: 80%;
                }
                &.choice {
                    margin: 0 0 10px;
                }
            }
        }
        .actions-toolbar {
            margin: 1rem 0;
            .secondary {
                display: none;
            }
            .primary {
                float: none;
            }
        }
        .form-newsletter-manage .field {
            &:before {
                display: none;
            }
            .checkbox {
                position: absolute;
                opacity: 0;
                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                }
                & + label:before {
                    content: '';
                    margin-right: 1rem;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #595959;
                }
                &:focus + label:before {
                    box-shadow: 0;
                }
                &:checked + label:after {
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: 9px;
                    background: white;
                    width: 2px;
                    height: 2px;
                    box-shadow:
                      2px 0 0 white,
                      4px 0 0 white,
                      4px -2px 0 white,
                      4px -4px 0 white,
                      4px -6px 0 white,
                      4px -8px 0 white;
                    transform: rotate(45deg);
                }
                &:checked + label:before {
                    background: #595959;
                }
            }
        }
    }
}
@include max-screen($screen__m){
    .account{
        .page.messages {
            margin-bottom: 0;
        }
        #maincontent .columns {
            margin-top: 0;
            padding-top: 50px;
        }
        .columns{
            .column.main{
                form{
                    .fieldset{
                        .field{
                            .extra{
                                margin-left: 2.5rem;
                            }
                        }
                    }
                }
            }
        }
        &.xnotif-stock-index {
            .column.main {
                overflow-x: auto;
            }
        }
    }
}
@include screen($screen__s, $screen__l){
    .account .columns .column.main form .fieldset {
        width: 100%;
    }
}

@include min-screen($screen__l){
    .account .columns .column.main form .fieldset {
        width: 48% !important;
    }
}


// order page

.account {
    .order-status {
        position: absolute;
        right: 0;
    }
}
.sales-order-view {
    .page-title-wrapper .order-date {
        margin: 0;
    }
    .column.main .actions-toolbar {
        display: none;
    }
    .order-links {
        display: none;
    }
    .order-details-items {
        border-bottom: none;
        .order-title {
            display: none;
        }
        .table-order-items .product-item-name {
            font-size: 14px;
        }
        .price-including-tax .price,
        .price-excluding-tax .price {
            font-size: 14px;
        }
        tfoot {
            th, td {
                padding: 5px;
            }
            tr {
                &:last-of-type {
                    th, td {
                        padding-bottom: 15px;
                    }
                }
            }
        }
        .actions-toolbar {
            display: none;
        }
    }
}
