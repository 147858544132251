.polarcore-header-links {
    display: flex;
}
.wishlist-link {
    display: none;
    margin-left: 1rem;
    a {
        &:hover {
            text-decoration: none;
            .wishlist-text {
                text-decoration: underline;
            }
        }
    }
    .wishlist-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: inherit;
    }
    .wishlist-icon {
        line-height: 10px;
        &:before {
            @include material-icon($icon-wishlist);
            font-size: 26px;
       }
   }
   .wishlist-text {
       font-size: 10px;
       font-weight: 500;
   }
   @include min-screen($screen__m) {
       display: block;
   }
}

