//
//  Loader component styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../_utils';

//
//  Variables
//  --------------------------------------------

$amscroll-loader__size: 20px;

//
//  Common
//  --------------------------------------------


.amscroll-loading-block {
    margin: 0 auto;
    padding: $amscroll__indent 0;
    width: $amscroll-loader__size;
    height: $amscroll-loader__size;
}

.amscroll-loading-icon {
    & {
        min-width: $amscroll-loader__size;
        height: $amscroll-loader__size;
    }

    &.-amscroll-animate {
        animation: amScrollRotate 1.5s linear infinite;
    }
}

