/**
*
* PolarCore Grid/Listings enhancements
*
*/

.p2-category-top, .catalogsearch-result-index {
    .category-view {
        .category-description {
            display: block;
            text-align: center;
            font-size: 18px;
            color: $petworld-cod-grey__color;
            width: 70%;
            margin: 0 auto 1rem;
            border-bottom: none;
        }
    }

    .page-title-wrapper {
        text-align: center;

        .page-title {
            color: #000;
            margin-bottom: 2rem;
            font-size: 30px;
            letter-spacing: 1px;
            text-transform: uppercase;
            border-bottom: 4px solid $petworld-blue__color;
            padding-bottom: 1rem;
            display: inline-block;
        }
    }
}

#amasty-shopby-product-list {
    .toolbar.toolbar-products:first-of-type {
        display: block;

        .toolbar-amount {
            font-size: 18px;

            .toolbar-number {
                font-weight: 700;
            }
        }
    }
}

.cms-index-index.page-layout-1column {
    .block.widget {
        .products-slider.products-grid {
            .product-item {
                width: 100%;

                .usp-list-product {
                    display: block;
                }

                .product-item-link__button {
                    display: block;
                }
            }
        }
    }
}

.products-grid {
    padding: 0;

    &.product-items.widget-product-grid {
        margin: 7px -5px;
    }

    .product-items {
        .product-item {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.products-grid {
    .product-items {
        .product-item {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product-item-details {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0.5rem;
        flex: 1;

        .product-item-name {
            width: 100%;
            font-size: 18px;
            color: $petworld-cod-grey__color;
            text-align: center;
            margin: 0;
            font-weight: 400;
            padding-right: 0;
            margin-bottom: 0.25rem;

            .product-item-link {
                white-space: normal !important;
            }
        }

        .product-item-description {
            display: none;
        }

        .link-stock-alert {
            display: none;
        }

        .price-box {
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            width: 100%;
            position: relative;
            margin: 0;

            .old-price {
                z-index: 1;
                text-decoration: line-through;
                color: #757575;
                margin-right: 1rem;

                .price {
                    font-size: 18px;
                    font-weight: 400;
                    color: #757575;
                }
            }

            .special-price {
                color: $petworld-special-price__color;
                font-size: 18px;
                font-weight: 900;
            }

            .special-price.loyalty {
                color: $petworld-medlem-blue__color;
                font-size: 18px;
                font-weight: 700;
            }

            .minimal-price-link {
                margin-top: 0;

                .price-wrapper {
                    font-size: 18px;
                    color: $petworld-member-blue__color;
                    font-weight: 700;
                }
            }

            .price {
                font-size: 18px;
                font-weight: 700;
                color: $petworld-cod-grey__color;
                padding-left: 0;
            }

            .special-price {
                &.loyalty + .old-price {
                    text-decoration: none;

                    .price {
                        font-weight: 700;
                    }
                }
            }
        }

        .usp-list-product {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 0;
            margin-bottom: 0.5rem;
            margin-top: auto;

            li {
                width: 100%;
                list-style: none;
                display: flex;
                align-items: center;
                font-size: 12px;
                text-align: left;
                color: #4b5699;

                i {
                    margin-right: 0.5rem;
                    font-size: 20px;
                }
            }

            .abonnement-desktop,
            .abonnement-mobile {
                justify-content: flex-start;
            }

            .on-sale,
            .unavailable {
                justify-content: flex-end;
            }
        }

        .product-item-link__button {
            width: 100%;
            margin-top: auto;

            .btn-list {
                background: none;
                color: #fff;
                transition: all 0.3s ease;
                padding: 11px 15px 9px;
                height: 40px;
                box-shadow: none;
                background: #09b5a7;
                border-radius: 0;

                &:hover {
                    background: darken(#09b5a7, 6%);
                }

                span {
                    text-transform: uppercase;
                    font-size: 14px;
                    letter-spacing: 1px;
                }
            }
        }

        &__top {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .amxnotif-container {
                .subscribe-popup {
                    display: none;
                }
            }
        }
    }

    .product-item {
        border-bottom: none;

        &-inner {
            /**
             * Hover function for swatches etc
             *
             */
            position: absolute;
            z-index: 10;
            top: calc(100% - 2rem);
            left: 0;
            right: 0;
            width: calc(100% - 2rem);
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
            background: white;
            padding: 1rem;
            width: 100%;

            &:before {
                display: block;
                content: '';
                position: relative;
                margin-top: -17px;
                border-top: 3px solid white;
                width: calc(100% + 2rem);
                margin-left: -1rem;
            }
        }
    }

    .product-item-actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-item:hover {

        .product-item-inner {
            display: block;
        }

        .product-item-info,
        .product-item-inner {
            // FF does not like absolute positioned outlines use box-shadow, #e0e6ee
            box-shadow: 0 0 0 1px $border-color__base;
        }
    }

    .product-item-inner {
        display: none;

        .swatch-attribute-options {
            justify-content: center;
        }
    }

    .product-item-actions {
        width: 100%;

        .action {
            padding: 0 0.5rem !important;
        }
    }
}

.block.widget {
    .product-item-details {
        padding: 1rem;
    }

    .product-item-actions {
        display: none;
    }
}

.products-list .product-item-details {
    text-align: left;

    .product-item-actions {
        display: flex;

        .actions-primary + .actions-secondary {
            width: auto;
            margin-left: 1rem;
            margin-right: auto;
        }
    }
}

.amstockstatus-category {
    width: 100%;
}
.product-info-stock-sku,
.amstockstatus-status-container {
    &.stock {
        text-align: right;

        .material-icons {
            margin-right: 0.5rem;
            font-size: 14px;
            line-height: 14px;
            vertical-align: middle;
        }

        &.available{
            color: #F5C125;
        }
        &.unavailable {

        }

        > span {
            font-size: 12px;
            line-height: 14px;
            width: auto;
            margin: 2px 0;
            color: #808080;
        }
    }
}

/* DESKTOP */
@include min-screen($screen__l) {
    .on-sale,
    .unavailable {
        width: 45%;
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-items {
                .product-item {
                    width: 25%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .products-slider {
        .product-item {
            padding: 0;
            z-index: 0;

            &-info {
                padding: 1rem;
            }
        }
    }
    .products-grid, .products-slider {
        .product-items {
            .product-item {
                padding: 0;
                z-index: 0;

                &-info {
                    padding: 1rem;
                }

                &-details {
                    &__top {
                        .abonnement-mobile {
                            display: none;
                        }

                        .abonnement-desktop {
                            width: 400px;
                        }
                    }
                }
            }
        }
    }
}

/* TABLET */
@include screen($screen__m, $screen__l) {
    .p2-category-top {
        #layered-filter-block {
            max-width: 100% !important;

            .filter-options-item {
                width: 150px !important;
            }
        }

        .category-view {
            .category-description {
                width: 100%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-items {
                .product-item {
                    width: 33.3333%;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 0;
                }
            }
        }
    }

    .products-grid {
        .product-items {
            .product-item {
                padding: 0;

                &-info {
                    padding: 0.5rem;
                }

                &-details {
                    &__top {
                        .abonnement-mobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* MOBILE */
@include max-screen($screen__m) {
    .page-title-wrapper {
        .page-title {
            margin-top: 0;
            font-size: 22px;
        }
    }

    .p2-category-top {
        display: flex;
        flex-direction: column-reverse;

        .page-title-wrapper {
            .page-title {
                font-size: 24px;
            }
        }

        .category-view {
            .category-description {
                display: none;
            }
        }

        /* active filters */
        #layered-filter-block.filter.active {
            .filter-title {
                strong {
                    background-color: transparent;
                    left: 0;
                    text-align: left;
                    width: 100%;
                    border: none;
                    text-transform: uppercase;
                    color: #000;
                    font-weight: 600;
                    padding-left: 1.5rem;

                    &:before {
                        display: none;
                    }

                    &:after {
                        float: right;
                        font-size: 2rem;
                        color: #000;
                    }
                }
            }

            .filter-subtitle {
                text-indent: 999px;
            }
        }

        #layered-filter-block {
            .filter-title {
                strong {
                    width: 45%;
                    background-color: #fff;
                    border-radius: 0px;
                    margin: 0;
                    border-bottom: 3px solid #b5b5b5;
                    color: #595959;
                    height: 45px;
                    font-weight: 400;
                    border: 1px solid #c2c2c2;
                    border-bottom: 3px solid #a3a3a3;
                    margin-right: 0.5rem;
                    text-align: left;
                    text-align-last: left;
                    font-size: 15px;
                    cursor: pointer;
                }
            }

            .filter-content {
                .filter-current {
                    border: none;

                    &-subtitle {
                        padding: 0;
                        margin-top: 0.75rem;

                        &:before {
                            display: none;
                        }
                    }

                    &.active {
                        padding-bottom: 0;
                    }

                    .items {
                        padding: 0 !important;
                        margin-top: 0.5rem;

                        .item {
                            .filter-value {
                                padding: 0.3rem !important;
                            }

                            .action.remove {
                                padding: 0.3rem 0.35rem 0.25rem 0.35rem;

                                &:before {
                                    top: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #amasty-shopby-product-list {
        .toolbar.toolbar-products:first-of-type {
            .toolbar-amount {
                font-size: 16px;
            }

            #sorter {
                width: 150px !important;
                margin-right: 0 !important;
            }

            .sorter-action.sort-asc {
                display: none;
            }

            .sorter-label {
                display: none !important;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-items {
                .product-item {
                    width: 50%;
                    margin: 0;
                }
            }
        }
    }

    .products-grid {
        margin: 0 -0.5rem;

        .product-item-details {
            padding-top: 0;

            .product-item-link__button {
                .btn-list {
                    span {
                        font-size: 12px;
                        letter-spacing: 0;
                    }
                }
            }
        }

        .product-items {
            margin: 0 -0.5rem;

            .product-item {
                padding: 0.5rem;

                &-info {
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
                }

                &-details {
                    &__top {
                        .product-reviews-summary {
                            margin-top: 0;
                        }

                        .price-box {
                            .price,
                            .price-wrapper,
                            .special-price {
                                font-size: 15px;
                            }
                        }

                        .usp-list-product {
                            margin-top: auto;
                            padding: 0 0.5rem;
                            margin-bottom: 0;

                            li {
                                font-size: 10px;

                                i {
                                    margin-right: 0.3rem;
                                    font-size: 14px;
                                }
                            }

                            .abonnement-desktop {
                                display: none;
                            }
                        }

                        .product-item-link__button {
                            padding: 0 0.5rem;
                            margin-top: auto;

                            .btn-list {
                                margin-top: 0.25rem;
                                background: #fff;
                                color: $petworld-orange__color;
                                border: 1px solid $petworld-orange__color;
                                border-bottom: 2px solid darken($petworld-orange__color, 18%);
                                margin-bottom: 0.5rem;
                                padding: 5px;

                                span {
                                    font-size: 0.7rem;
                                }

                                &:hover {
                                    color: #fff;
                                    background: $petworld-orange__color;
                                }
                            }
                        }
                    }
                }

                &-name {
                    font-size: 16px;
                    height: auto;

                    .product-item-link {
                        white-space: nowrap;
                        padding: 0 0.5rem;
                    }
                }
            }
        }
    }
}

.block.widget.slider-grid-widget {
    .owl-carousel.additional-carousel-products {
        position: relative;
        padding: 0 30px;
        box-sizing: border-box;

        .owl-stage {
            display: flex;
        }

        .owl-item {
            display: flex;

            .product-item-details {
                padding: 0;
            }

            .product-item-details__top {
                flex-direction: column;

                .product-item-name {
                    margin-bottom: auto;
                }

                .price-box {
                    margin: 0;
                }

                .usp-list-product {
                    margin-top: 0;
                }

                .product-item-link__button {
                    margin-top: 0;

                    span {
                        letter-spacing: 0;
                    }
                }
            }
        }

        .owl-nav {
            .owl-prev, .owl-next {
                position: absolute;
                top: 50%;
                box-shadow: none;
                transform: translateY(-50%);

                &:hover {
                    opacity: 0.8;
                }

                span {
                    font-size: 50px;
                    font-weight: 300;
                    line-height: 1;
                }

            }

            .owl-prev {
                left: 0;
            }

            .owl-next {
                right: 0;
            }
        }
    }
}
