/* slider */
.amslider{
    position: relative;
    left: 50%;
    width: calc(100% - 1px);
    transform: translateX(-50%);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.amslider *{
    margin: 0;
    padding: 0;
}
.amslider .swiper-container{
    margin: 20px auto;
}
.amslider .swiper-wrapper{
    display: -moz-grid-line;
}
.amslider .swiper-pagination-bullets{
    bottom: 5px;
    left: 0;
    width: 100%;
}
.amslider .swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 5px;
}
.amslider-container .amslider-header-container{
    background: #f58c12;
    height: 35px;
    padding: 5px 0 5px 35px;
}
.amslider-container .amslider-header-container .amslider-header{
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}
.amslider-container .amslider-header-container span{
    margin: 0;
    color: #fff;
    font-size: 22px;
    line-height: 35px;
    text-transform: none;
}
.amslider .swiper-slide{
    text-align: center;
	padding: 1px 0;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

}
.amslider .swiper-slide .swiper-brand{
    margin: 10px 0;
    padding: 0 10px;
    border: 1px solid gray;
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 5px);
    justify-content: center;
    align-items: center;
}
/* safari*/
.amslider .swiper-slide .swiper-brand:not(:root:root){
     padding: 10px;
}
.amslider .swiper-slide .swiper-brand img{
    height: auto;
}
.amslider .swiper-slide .swiper-brand.brand-label img{
    margin-bottom: 35px;
}
.amslider .swiper-slide .brand-title{
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    padding: 0 5px;
    font-size: 15px;
    line-height: 1.2;
}
.amslider .swiper-slide a:hover{
    text-decoration: none;
}

.brands-filters{
    .letters{
        display: flex;
        flex-direction: column;
        .letters-box{
            width: 100%;
            justify-content: center;
            display: flex;
            margin: 0;
            a.letter-all{
				color: #000;
				margin-bottom: 2rem;
				font-size: 30px;
				letter-spacing: 1px;
				text-transform: uppercase;
				border-bottom: 4px solid #4b5699 !important;
				display: inline-block;
				padding: 0 0 1rem 0;
				font-weight: 700;
            }
            &:nth-of-type(2){
                margin-bottom: 2rem;
            }
        }
    }
}
dl.brands-letter{
    display: flex;
    border-bottom: none;
    border-top: 1px solid silver;
    padding: 2rem 0;
    margin: 0;
    dt{
        width: 70px !important;
        font-size: 6rem !important;
        font-weight: 400;
        margin-right: 1rem;
        color: $petworld-blue__color;
        border-bottom: none !important;
        span{
            background: transparent !important;

        }
    }
    dd{
        width: 100%;
        display: flex;
        ul{
            display:flex;
            flex-wrap: wrap;
            align-items: center;
            list-style: none;
            width: 100%;
            li{
                width: 33%;
                margin: 0 !important;
                max-width: none;
                min-width: auto;
                min-height: auto;
                a{
                    color: black !important;
                    flex-direction: initial !important;
                    padding: 0.5rem 0;
                    .label{
                        &:before{
                            display: none !important;
                        }
                    }
                    .am-image-wrapper{
                        height: auto !important;
                    }
                }
            }
        }
    }
}
.brands-filters .letters a{
    border: none !important;
    border-radius: 0 !important;
    min-width: 17px;
    float: left;
    color: $petworld-blue__color;
    cursor: pointer;
    min-height: 20px;
    display: block;
    padding: 1rem 1rem;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    line-height: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    text-decoration: none;
    background-color: transparent !important;
    transition: all 50ms ease;
    &:hover{
        color: #000;
    }
}
.brands-filters .letters-box a{
    margin-bottom: 10px;
    border-right: none !important;
}
.brands-filters .letters-box a:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    clear: left;
}
.brands-filters .letters-box a:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
.brands-filters .letters-box a.disabled{
    opacity: 1 !important;
    pointer-events: none;
    color: #dadada;
}
.brands-filters .letters a.hide{
    display: none;
}
.brands-filters .letters a:hover, .brands-filters .letters a.active{
    background: #FBF8E9;
}

.ambrands-search-wrapper{
    margin:  0 auto;
    display: block;
}
.ambrands-search-wrapper .ambrands-search-input{
    width: 365px;
    margin-bottom: 5px;
    display: block;
    max-width: 100%;
}
.ambrands-search-wrapper #livesearch a{
    width: 100%;
    padding: 5px 10px;
    display: block;
    float: left;
}
.ambrands-search-wrapper #livesearch{
    display: none;
    width: 365px;
    max-width: 100%;
    position: absolute;
    background-color: #fff;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #A5ACB2;
    z-index: 99;
}
.icon .brand-label{
    float: left;
}
.brand-item .no-img{
    display: none;
}
.brand-images .brands-letter dt{
    width: 10%;
}
.brand-images li.brand-item{
    list-style-type: none;
    text-align: center;
	font-size: 18px;
}
ul.bare-list img{
    display: block;
    margin: 0 auto;
}

/* Tablet */
@include max-screen ($screen__l){
    .brands-filters .letters a{
        min-width: 18px;
        margin: 0 5px;
        border: 1px solid #CCC;
        border-radius: 5px;
    }
    .letters-box{
        display: block !important;
    }
}


/*filter - mobile*/
@include max-screen($screen__m){
    .brands-filters {
        min-height: 50px;
        width: 100%;
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        .input-box{
            display: none;
			float: right;
        }
        .letters{
            display: flex;
            flex-direction: column;
            width: 100%;
            .letters-box{
                width: 100%;
                display: flex !important;
                a{
                    width: 11%;
                    margin: 0;
                    white-space: nowrap;
                    border: none !important;
                }
                a.letter-all{
                    width: 100%;
                }
                &:first-of-type a{
                    border-bottom: 1px solid silver;
                }
                &:nth-of-type(2){
                    margin-bottom: 0rem;
                    flex-wrap: wrap;
                }
            }
        }
    }
    dl.brands-letter{
        display: flex;
        border-bottom: none;
        border-top: 1px solid silver;
        padding: 2rem 0;
        margin: 0;
        dt{
            width: 70px !important;
            font-size: 6rem !important;
            font-weight: 400;
            margin: 0 1rem;
            color: $petworld-blue__color;
        }
        dd{
            width: 100%;
            display: flex;
            ul{
                display:flex;
                flex-wrap: wrap;
                align-items: center;
                list-style: none;
                padding-left: 0;
                width: 100%;
                li{
                    width: 50% !important;
                }
            }
        }
    }
}
