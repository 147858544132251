//
//  List default styles reset
//  ---------------------------------------------

.abs-reset-list {
    @include lib-list-reset-styles();

    > li {
        margin: 0;
    }
}

//
//  Secondary button
//  ---------------------------------------------

.abs-revert-to-action-secondary {
    @extend .abs-revert-secondary-color;
    border-radius: $button__border-radius;

    &:not(:focus) {
        box-shadow: $button__shadow;
    }

    &:active {
        box-shadow: $button__shadow-active;
    }
}

//
//  Link as a button
//  ---------------------------------------------

.abs-action-link-button {
    @include lib-button(
        $_button-border: $polarcore-button__border,
        $_button-border-hover: $polarcore-button__border,
        $_button-background-hover: #f0f0f0
    );
    @include lib-link-as-button();
    border-width: 1px;
    font-weight: 400;
    background: transparent;
    border-color: $border-color__base;
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-radius: 0;
}

//
//  Button as a link
//  ---------------------------------------------

.abs-action-button-as-link {
    @include lib-button-as-link();
    margin: 0;
    font-weight: $font-weight__regular;
    border-radius: 0;

    &:active,
    &:not(:focus) {
        box-shadow: none;
    }
}

//
//  Button revert secondary color
//  ---------------------------------------------

.abs-revert-secondary-color {
    @include lib-button-revert-secondary-color();
}

//
//  Button revert secondary size
//  ---------------------------------------------

.abs-revert-secondary-size {
    @include lib-button-revert-secondary-size();
}

//
//  Large button
//  ---------------------------------------------

.abs-button-l {
    @include lib-button-l();
}

//
//  Product options list
//  ---------------------------------------------

@mixin abs-product-options-list() {
    dt {
        clear: left;
        float: left;
        margin: 0 $indent__s $indent__xs 0;

        &:after {
            content: ': ';
        }
    }

    dd {
        display: inline-block;
        float: left;
        margin: 0 0 $indent__xs;
    }
}

.abs-product-options-list {
    @include abs-product-options-list();
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-product-options-list-desktop {
        @include abs-product-options-list();
    }
}

//
//  Button reset width, floats, margins
//  ---------------------------------------------

.abs-button-responsive {
    @include lib-button-responsive();
}

@include min-screen($screen__m) {
    .abs-button-desktop {
        width: auto;
    }
}

//
//  Blocks in 2 columns
//  ---------------------------------------------

@mixin abs-blocks-2columns() {
    width: 48%;

    &:nth-child(1) {
        clear: left;
        float: left;
    }

    &:nth-child(2) {
        float: right;

        & + * {
            clear: both;
        }
    }
};

@include min-screen($screen__m) {
    .abs-blocks-2columns {
        @include abs-blocks-2columns();
    }
}

@include min-screen($screen__s) {
    .abs-blocks-2columns-s {
        @include abs-blocks-2columns();
    }
}

//
//  Reset image alignment in container
//  ---------------------------------------------

.abs-reset-image-wrapper {
    height: auto;
    padding: 0 !important;

    .product-image-photo {
        position: static;
    }
}

//
//  Adaptive images
//  ---------------------------------------------

.abs-adaptive-images {
    display: block;
    height: auto;
    max-width: 100%;
}

.abs-adaptive-images-centered {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

//
//  Title for login blocks
//  ---------------------------------------------

.abs-login-block-title {
    border-bottom: 1px solid $secondary__color;
    font-size: 18px;;
    margin-bottom: 15px;
    padding-bottom: 12px;

    strong {
        font-weight: 500;
    }
}

//
//  Simple Dropdown
//  ---------------------------------------------

.abs-dropdown-simple {
    @include lib-dropdown(
        $_dropdown-list-item-padding: 5px 5px 5px 23px,
        $_dropdown-list-min-width: 200px,
        $_icon-font-margin: 0 0 0 5px,
        $_icon-font-vertical-align: middle
    );

    ul.items {
        padding: 6px 0;
    }
}

//
//  Input quantity
//  ---------------------------------------------

.abs-input-qty {
    text-align: center;
    width: 54px;
}

//
//  Marging for blocks & widgets
//  ---------------------------------------------

.abs-margin-for-blocks-and-widgets {
    margin-bottom: $indent__xl;
}

@include min-screen($screen__m) {
    .abs-margin-for-blocks-and-widgets-desktop {
        margin-bottom: $indent__xl + $indent__s;
    }
}

//
//  Remove button for blocks
//  ---------------------------------------------

.abs-remove-button-for-blocks {
    @include lib-icon-font(
        $icon-remove,
        $_icon-font-size: 12px,
        $_icon-font-line-height: 15px,
        $_icon-font-text-hide: true,
        $_icon-font-color: $color-gray60,
        $_icon-font-color-hover: $color-gray-darken4,
        $_icon-font-color-active: $color-gray60
    );
}

//
//  Link
//  ---------------------------------------------

.abs-like-link {
    @include lib-link();
    cursor: pointer;
}

//
//  Reset left margin
//  ---------------------------------------------

@mixin abs-reset-left-margin() {
    margin-left: 0;
};

.abs-reset-left-margin {
    @include abs-reset-left-margin();
}

@include min-screen($screen__m) {
    .abs-reset-left-margin-desktop {
        @include abs-reset-left-margin();
    }
}

@include min-screen($screen__s) {
    .abs-reset-left-margin-desktop-s {
        @include abs-reset-left-margin;
    }
}

//
//  Action with icon remove with text
//  ---------------------------------------------

.abs-action-remove {
    @extend .abs-action-button-as-link;
    line-height: normal;
    margin-left: 73%;
    position: absolute;
    top: 34px;
    width: auto;
}

//
//  Action with icon remove with text for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-action-remove-desktop {
        margin-left: 90%;
    }
}

//
//  Add Recipient
//  ---------------------------------------------

.abs-add-fields {
    .fieldset {
        .field {
            .control {
                width: 70%;
            }
        }

        .actions-toolbar {
            @extend .abs-add-clearfix;

            > .secondary {
                .action {
                    &.add {
                        margin-top: $indent__l;
                    }
                }

                float: left;
            }
        }

        .fields {
            .actions-toolbar {
                margin: 0;
            }
        }
    }

    .additional {
        margin-top: 55px;
        position: relative;
    }

    .action {
        &.remove {
            @extend .abs-action-remove;
        }
    }

    .message {
        &.notice {
            margin: $indent__l 0 0;
        }
    }
}

//
//  Add Recipient for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-add-fields-desktop {
        .fieldset {
            .field {
                .control {
                    width: auto;
                }
            }

            .additional {
                .action {
                    &.remove {
                        @extend .abs-action-remove-desktop;
                    }
                }
            }
        }
    }
}

//
//  Margin for forms
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-margin-for-forms-desktop {
        margin-left: $form-field-type-label-inline__width;
    }
}

//
//  Visibility hidden / show visibility hidden
//  ---------------------------------------------

.abs-hidden {
    @include lib-visibility-hidden();
};

.abs-hidden {
    @extend .abs-hidden;
}

//
//  Visually hidden / show visually hidden
//  ---------------------------------------------

.abs-visually-hidden {
    @include lib-visually-hidden();
};

@include max-screen($screen__s) {
    .abs-visually-hidden-mobile {
        @include lib-visually-hidden();
    }
}

@include max-screen($screen__m) {
    .abs-visually-hidden-mobile-m {
        @include lib-visually-hidden();
    }
}

@include min-screen($screen__s) {
    .abs-visually-hidden-desktop-s {
        @include lib-visually-hidden();
    }
}

@include min-screen($screen__m) {
    .abs-visually-hidden-desktop {
        @include lib-visually-hidden();
    }
}

//
//  Visually hidden reset
//  ---------------------------------------------

.abs-visually-hidden-reset {
    @include lib-visually-hidden-reset();
}

//
//  Clearfix
//  ---------------------------------------------

.abs-add-clearfix {
    @include lib-clearfix();
};

@include min-screen($screen__m) {
    .abs-add-clearfix-desktop {
        @include lib-clearfix();
    }
}

@include min-screen($screen__s) {
    .abs-add-clearfix-desktop-s {
        @include lib-clearfix();
    }
}

@include max-screen($screen__s) {
    .abs-add-clearfix-mobile {
        @include lib-clearfix();
    }
}

@include max-screen($screen__m) {
    .abs-add-clearfix-mobile-m {
        @include lib-clearfix();
    }
}

//
//  Box-sizing
//  ---------------------------------------------

@mixin abs-add-box-sizing() {
    box-sizing: border-box;
};

.abs-add-box-sizing {
    @include abs-add-box-sizing();
}

@include min-screen($screen__m) {
    .abs-add-box-sizing-desktop {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__s) {
    .abs-add-box-sizing-desktop-s {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__m) {
    .abs-add-box-sizing-desktop-m {
        @include abs-add-box-sizing();
    }
}

//
//  Revert field type
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-revert-field-type-desktop {
        .fieldset {
            > .field,
            .fields > .field {
                @include lib-form-field-type-revert($_type: block);

                &:not(:first-child):last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

//
//  Settings icons
//  ---------------------------------------------

.abs-navigation-icon {
    @include lib-icon-font(
        $_icon-font-content: $icon-down,
        $_icon-font-size: 34px,
        $_icon-font-line-height: 1.2,
        $_icon-font-position: after,
        $_icon-font-display: block
    );

    &:after {
        position: absolute;
        right: 5px;
        top: 0;
    }
}

//
//  Split button
//  ---------------------------------------------

.abs-split-button {
    @include lib-dropdown-split(
        $_options-selector : '.items',
        $_dropdown-split-button-border-radius-fix: true
    );
    vertical-align: middle;
}

//
//  Field 2 column
//  ---------------------------------------------

@mixin abs-form-field-column-2() {
    .fieldset {
        .field {
            @include lib-form-field-column($_column: true);

            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: 0;
            }

            .field {
                padding: 0;
                width: 100%;
            }
        }
    }
};

@include min-screen($screen__m) {
    .abs-form-field-column-2 {
        @include abs-form-field-column-2();
    }
}

@include min-screen($screen__s) {
    .abs-form-field-column-2-s {
        @include abs-form-field-column-2();
    }
}

//
//  Field 1 column
//  ---------------------------------------------

.abs-form-field-revert-column-1 {
    @include lib-form-field-column-number($_column-number: 1);
};

@include min-screen($screen__m) {
    .abs-form-field-revert-column-1 {
        @include lib-form-field-column-number($_column-number: 1);
    }
}

@include min-screen($screen__s) {
    .abs-form-field-revert-column-1-s {
        @include lib-form-field-column-number($_column-number: 1);
    }
}

//
//  Checkout shipping methods title
//  ---------------------------------------------

.abs-methods-shipping-title {
    font-weight: $font-weight__semibold;
    font-size: $font-size__base;
    margin-bottom: 15px;
}

//
//  Checkout order review
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-checkout-order-review {
        tbody > tr {
            @extend .abs-add-clearfix-mobile;
            &:not(:last-child) {
                border-bottom: $border-width__base solid $border-color__base;
            }

            > td.col {
                &.item {
                    &:before {
                        display: none;
                    }
                }

                &.qty,
                &.price,
                &.subtotal {
                    box-sizing: border-box;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &:last-child {
                    border: 0;
                }
            }
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Add to Actions
//  ---------------------------------------------

.abs-actions-addto {
    color: $addto-color;
    display: inline-block;
    font-weight: $font-weight__semibold;
    letter-spacing: .05em;
    text-transform: uppercase;
    @include lib-icon-font(
        $_icon-font-content: '',
        $_icon-font-size: 16px,
        $_icon-font-line-height: 16px,
        $_icon-font-margin: -2px 5px 0 0,
        $_icon-font-vertical-align: middle
    );

    &:hover {
        color: $addto-hover-color;
        text-decoration: none;
    }

    &:before {
        width: 18px;
    }
}

//
//  Box-tocart block
//  ---------------------------------------------

.abs-box-tocart {
    margin: 0 0 $indent__l;
}

//
//  General pages forms
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-forms-general-desktop {
        max-width: 500px;

        .legend {
            @include lib-visually-hidden();
        }

        .legend + br {
            display: none;
        }
    }
}

//
//  Revert side paddings
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-revert-side-paddings {
        padding-left: 0;
        padding-right: 0;
    }
}

//
//  Abstract toggle title block
//  ---------------------------------------------

@mixin abs-toggling-title {
    border-top: $border-width__base solid $border-color__base;
    cursor: pointer;
    margin-bottom: 0;
    position: relative;

    &:after {
        position: absolute;
        right: $indent__base;
        top: $indent__s;
    }
};

.abs-toggling-title {
    @include abs-toggling-title();
    padding: $indent__s $indent__xl $indent__s $mobile-cart-padding;
    @include lib-icon-font(
        $icon-down,
        $_icon-font-size: 12px,
        $_icon-font-line-height: 12px,
        $_icon-font-text-hide: true,
        $_icon-font-margin: 3px 0 0,
        $_icon-font-position: after,
        $_icon-font-display: block
    );
}

@include max-screen($screen__m) {
    .abs-toggling-title-mobile {
        @include abs-toggling-title();
        border-bottom: $border-width__base solid $border-color__base;
        padding: $indent__s $indent__xl $indent__s $layout__width-xs-indent;
        @include lib-icon-font(
            $icon-down,
            $_icon-font-size: 12px,
            $_icon-font-text-hide: false,
            $_icon-font-margin: 3px 0 0 0,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
        }
    }
}

//
//  Cart discount block
//  ---------------------------------------------

.abs-cart-block {
    margin: 0;

    > .title {
        @extend .abs-toggling-title;
        &:after {
            color: $color-gray52;
        }
    }

    .content {
        display: none;
        padding: 5px $mobile-cart-padding 23px;
        position: relative;
    }

    &.active {
        > .title {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
        }

        .content {
            display: block;
        }
    }
}

.abs-cart-block-content {
    margin: 0;
}

//
//  Checkout order review price
//  ---------------------------------------------

.abs-checkout-cart-price {
    color: $primary__color__lighter;
    font-size: $font-size__base;;
    font-weight: $font-weight__bold;
}

//
//  Checkout order product name
//  ---------------------------------------------

.abs-checkout-product-name {
    font-size: 18px;;
    font-weight: $font-weight__light;
    margin: 0;
}

//
//  Mobile checkout order product name
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-product-items-summary {
        tbody {
            .col {
                padding: $indent__s 0 0;

                &:last-child {
                    padding-bottom: $indent__s;
                }
            }
        }

        tfoot {
            .mark {
                padding-bottom: $indent__xs;
                text-align: left;
            }

            .amount {
                padding-top: 0;
                text-align: left;
            }

            .grand.totals {
                font-size: $font-size__base;;

                .mark {
                    padding-bottom: 0;
                    padding-top: $indent__xs;
                }

                .amount {
                    padding-bottom: $indent__s;
                    padding-top: 0;

                    strong {
                        border-top: none;
                    }
                }
            }
        }
    }
}

//
//  Account pages: title
//  ---------------------------------------------

.abs-account-title {
    > strong,
    > span {
        font-size: 22px;;
        font-weight: $font-weight__light;
    }

    border-bottom: 1px solid $account-title-border-color;
    margin-bottom: $indent__m;
    padding-bottom: $indent__s;
}

//
//  Account pages: block font size
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-account-block-font-size {
        font-size: $font-size__base;;
    }
}

//
//  Account pages: block line-height
//  ---------------------------------------------

.abs-account-block-line-height {
    line-height: 24px;
}

//
//  Account pages: margin for table
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-account-table-margin-mobile {
        margin-top: -$indent__base;
    }
}
@include min-screen($screen__m) {
    .abs-account-table-margin-desktop {
        margin-top: -$indent__m;
    }
}

//
//  Account pages: table col actions
//  ---------------------------------------------

@mixin abs-col-no-prefix() {
    @include max-screen($screen__s) {
        &:before {
            @include lib-visually-hidden();
        }
    }
}

//
//  Account pages: order table summary
//  ---------------------------------------------

.abs-account-summary {
    td {
        background: $sidebar__background-color;
    }
}

//
//  Action print with icon
//  ---------------------------------------------

@mixin abs-action-print() {
    @include lib-icon-font(
        $icon-print,
        $_icon-font-size: 16px,
        $_icon-font-line-height: 16px,
        $_icon-font-margin: 0 4px 0 0,
        $_icon-font-position: before
    );

    &:hover {
        text-decoration: underline;
    }
};

.abs-action-print {
    @include abs-action-print();
}

@include min-screen($screen__m) {
    .abs-action-print {
        @include abs-action-print();
    }
}

@include min-screen($screen__s) {
    .abs-action-print-s {
        @include abs-action-print();
    }
}

//
//  Excl/Incl tax
//  ---------------------------------------------

.abs-incl-excl-tax {
    .price-including-tax,
    .price-excluding-tax {
        color: $cart-price-color;
        font-size: 18px;;
        display: block;
        line-height: 1;
        white-space: nowrap;

        .price {
            font-weight: $font-weight__bold;
        }
    }
}

.abs-adjustment-incl-excl-tax {
    .price-including-tax,
    .price-excluding-tax {
        font-size: 14px;
        display: inline-block;
    }

    .price-including-tax + .price-excluding-tax {
        font-size: 11px;;
        display: inline-block;

        &:before {
            content: '('attr(data-label)': ';
        }

        &:after {
            content: ')';
        }
    }
}

//
//  Cart tax total
//  ---------------------------------------------

.abs-tax-total {
    cursor: pointer;
    padding-right: $indent__s;
    position: relative;

    @include lib-icon-font(
        $icon-down,
        $_icon-font-size: 8px,
        $_icon-font-line-height: 8px,
        $_icon-font-margin: 3px 0 0 0,
        $_icon-font-position: after
    );

    &:after {
        position: absolute;
        right: -2px;
        top: 4px;
    }
}

.abs-tax-total-expanded {
    @include lib-icon-font-symbol(
        $_icon-font-content: $icon-up,
        $_icon-font-position: after
    );
}

//
//  Forms: margin-bottom for small forms
//  ---------------------------------------------

.abs-forms-margin-small {
    margin-bottom: $indent__base;
}

//
//  Forms: margin-bottom for small forms
//  ---------------------------------------------

.abs-rating-summary {
    .rating {
        &-summary {
            display: table-row;
        }

        &-label {
            display: table-cell;
            padding-bottom: $indent__xs;
            padding-right: $indent__m;
            padding-top: 1px;
            vertical-align: top;
        }

        &-result {
            display: table-cell;
            vertical-align: top;
        }
    }
}

//
//  Account pages: actions
//  ---------------------------------------------

.abs-account-actions {
    &:after {
        border-left: 1px solid $primary__color__light;
        content: '';
        display: inline-block;
        height: 12px;
        margin: 0 $indent__s;
        vertical-align: -1px;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
}

//
//  Account blocks
//  ---------------------------------------------

.abs-account-blocks {
    .block-title {
        @extend .abs-account-title;

        > .action {
            margin-left: 15px;
        }
    }

    .box-title {
        > span {
            font-size: $font-size__base;;
        }

        display: inline-block;
        margin: 0 0 $indent__s;

        > .action {
            font-weight: 400;
            margin-left: $indent__s;
        }
    }
}

//
//  Add colon
//  ---------------------------------------------

.abs-colon {
    &:after {
        content: ': ';
    }
}

//
//  Icon - create add
//  ---------------------------------------------

.abs-icon-add {
    @include lib-icon-font(
        $_icon-font-content: $icon-expand,
        $_icon-font-size: 10px,
        $_icon-font-line-height: 10px,
        $_icon-font-vertical-align: middle
    );
}

@include max-screen($screen__m) {
    .abs-icon-add-mobile {
        @include lib-icon-font(
            $_icon-font-content: $icon-expand,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 10px,
            $_icon-font-vertical-align: middle,
            $_icon-font-margin: 0 5px 0 0,
            $_icon-font-display: block
        );
    }
}

//
//  Dropdown items - create new
//  ---------------------------------------------

.abs-dropdown-items-new {
    .items .item:last-child {
        &:hover {
            background: $dropdown-list-item__hover;
        }
    }

    .action.new {
        @extend .abs-icon-add;
        &:before {
            margin-left: -17px;
            margin-right: $indent__xs;
        }
    }
}

//
//  Abstract no display
//  ---------------------------------------------

@mixin abs-no-display() {
    display: none !important;
};

.abs-no-display {
    @include abs-no-display;
}

@include max-screen($screen__s) {
    .abs-no-display-s {
        @include abs-no-display;
    }
}

@include min-screen($screen__m) {
    .abs-no-display-desktop {
        @include abs-no-display;
    }
}

//
//  Status
//  ---------------------------------------------

.abs-status {
    border: 2px solid $border-color__base;
    border-radius: 3px;
    display: inline-block;
    margin: 3px 0 0;
    padding: 2px $indent__s;
    text-transform: uppercase;
    vertical-align: top;
}

@include min-screen($screen__m) {
    .abs-status-desktop {
        margin-top: 6px;
        padding: $indent__xs $indent__s;
    }
}

//
//  Page title - orders pages
//  ---------------------------------------------

.abs-title-orders {
    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-right: 25px;
            }

            .order-date {
                font-size: 16px;
                margin-bottom: $indent__s;

                .label {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .abs-title-orders-mobile {
        .page-main {
            .page-title-wrapper {
                .page-title {
                    display: block;
                }

                .order-status {
                    $order-status-indent: ceil($h1__margin-bottom/2);
                    margin: -$order-status-indent 0 $indent__s;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .abs-title-orders-desktop {
        .page-main {
            .page-title-wrapper {
                .order-date {
                    $order-date-indent: ceil($h1__margin-bottom/1.5);
                    margin: -$order-date-indent 0 18px;
                }
            }
        }
    }
}

//
//  Table striped
//  ---------------------------------------------

.abs-table-striped {
    @include lib-table-striped(
        $_stripped-highlight: even
    );
    @include lib-table-bordered(
        $_table_type: light
    );
}

@include max-screen($screen__s) {
    .abs-table-striped-mobile {
        > tbody > tr > td:last-child {
            border: 0;
        }
    }
}

//
//  Table bordered desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-table-bordered-desktop {
        @include lib-table-bordered(
            $_table_type: light,
            $_table_border-width: 1px
        );
    }
}

//
//  Pager toolbar for non-catalog pages desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-pager-toolbar {
        position: relative;

        .toolbar-amount,
        .limiter {
            position: relative;
            z-index: 1;
        }

        .toolbar-amount {
            line-height: $pager__line-height;
            padding: 0;
        }

        .pages {
            position: absolute;
            width: 100%;
            z-index: 0;
        }
    }
}

//
//  Items counter in blocks
//  ---------------------------------------------

.abs-block-items-counter {
    color: $primary__color__lighter;
    font-size: 12px;
    white-space: nowrap;
}

//
//  Sidebar and widget blocks title
//  ---------------------------------------------

.abs-block-widget-title {
    margin: 0 0 $indent__base;

    strong {
        font-size: $font-size__l;
        font-weight: $font-weight__light;
    }
}

//
//  Shopping cart items
//  ---------------------------------------------

.abs-shopping-cart-items {
    margin-bottom: $indent__base;

    .actions.main {
        .continue,
        .clear {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .abs-shopping-cart-items-desktop {
        @include lib-layout-column(2, 1, $layout-column-checkout__width-main);
        @include abs-add-box-sizing();
        padding-right: 4%;
        position: relative;
    }
}

//
//  Remove top border
//  ---------------------------------------------

@mixin abs-no-border-top() {
    @include max-screen($screen__s) {
        border-top: 0;
    }
}

//
//  Remove bottom border
//  ---------------------------------------------

@mixin abs-no-border-bottom() {
    @include max-screen($screen__s) {
        border-bottom: 0;
    }
}

//
//  Form Field Date
//  ---------------------------------------------

.abs-field-date {
    .control {
        position: relative;
        @extend .abs-add-box-sizing;
    }

    input {
        @extend .abs-field-date-input;
    }
}

//
//  Form Field Date Input
//  ---------------------------------------------

.abs-field-date-input {
    margin-right: $indent__s;
    width: calc('100% -' $icon-calendar__font-size + $indent__s);
}

//
//  Form Field Tooltip
//  ---------------------------------------------

.abs-field-tooltip {
    @extend .abs-add-box-sizing;
    position: relative;

    input {
        margin-right: $indent__s;
        width: calc('100% -' $checkout-tooltip-icon__font-size + $indent__s + $indent__xs);
    }
}

//
//  Checkout Tooltip Content (position: top)
//  ---------------------------------------------

@mixin abs-checkout-tooltip-content-position-top() {
    right: $checkout-tooltip-content-mobile__right;
    top: $checkout-tooltip-content-mobile__top;
    left: auto;

    &:before,
    &:after {
        @include lib-arrow(
            $_position: top,
            $_size: $checkout-tooltip-icon-arrow__font-size,
            $_color: $checkout-tooltip-content__background-color
        );
        margin-top: $checkout-tooltip-icon-arrow__left;
        right: $indent__s;
        left: auto;
        top: 0;
    }

    &:before {
        border-bottom-color: $checkout-tooltip-content__active__border-color;
    }

    &:after {
        border-bottom-color: $checkout-tooltip-content__background-color;
        top: 1px;
    }
};

@mixin abs-checkout-tooltip-content-position-top-mobile() {
    @include max-screen($screen__m + 1) {
        @include abs-checkout-tooltip-content-position-top();
    }
}

.abs-checkout-tooltip-content-position-top {
    @include abs-checkout-tooltip-content-position-top();
}

//
//  Checkout title
//  ---------------------------------------------

.abs-checkout-title {
    padding-bottom: $checkout-step-title__padding;
    @include lib-typography(
        $_font-size: $checkout-step-title__font-size,
        $_font-weight: $checkout-step-title__font-weight,
        $_font-family: false,
        $_font-style: false,
        $_line-height: false
    );
}

//
//  Shopping cart sidebar and checkout sidebar totals
//  ---------------------------------------------

.abs-sidebar-totals {
    border-top: 1px solid $border-color__base;
    padding-top: 10px;

    tbody,
    tfoot {
        .mark {
            border: 0;
            font-weight: $font-weight__regular;
            padding: 6px 0;
        }

        .amount {
            border: 0;
            font-weight: $font-weight__regular;
            padding: 6px 0 6px 14px;
            text-align: right;
            white-space: nowrap;
        }
    }

    .table-caption {
        @extend .abs-no-display;
    }

    .grand {
        th,
        td {
            padding: 11px 0;
        }

        strong {
            display: inline-block;
            font-weight: $font-weight__semibold;
            padding: 3px 0 0;
        }

        .mark {
            font-size: 18px;;
            border-top: 1px solid $border-color__base;
            padding-right: $indent__s;
        }

        .amount {
            font-size: 18px;;
            border-top: 1px solid $border-color__base;
        }
    }

    .msrp {
        margin-bottom: $indent__s;
    }

    tbody tr:last-child td {
        padding-bottom: 19px;
    }

    .totals-tax {
        &-summary {
            .mark,
            .amount {
                border-bottom: $border-width__base solid $border-color__base;
                border-top: $border-width__base solid $border-color__base;
                cursor: pointer;
            }

            .amount .price {
                padding-right: $indent__base;
                position: relative;
                @include lib-icon-font(
                    $icon-down,
                    $_icon-font-size: 12px,
                    $_icon-font-line-height: 12px,
                    $_icon-font-text-hide: true,
                    $_icon-font-position: after
                );

                &:after {
                    position: absolute;
                    right: 3px;
                    top: 3px;
                }
            }

            &.expanded {
                .mark,
                .amount {
                    border-bottom: 0;
                }

                .amount .price {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }

        &-details {
            border-bottom: $border-width__base solid $border-color__base;
            display: none;

            &.shown {
                display: table-row;
            }
        }
    }

    .table-wrapper {
        margin-bottom: 0;
    }
}

//
//  Shopping cart mobile sidebar
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-sidebar-totals-mobile {
        th {
            @include abs-col-no-prefix();
        }

        td {
            @include abs-col-no-prefix();
        }

        tbody tr:not(:last-child) {
            td {
                @include abs-no-border-bottom();
                @include abs-no-border-top();
            }
        }

        .amount {
            text-align: right;
        }
    }
}

//
//  Shopping cart and payment discount codes block
//  ---------------------------------------------

.abs-discount-block {
    .block {
        @extend .abs-cart-block;

        > .title {
            strong {
                color: $color-blue1;
                font-weight: $font-weight__regular;
            }
        }
    }

    .actions-toolbar {
        display: table-cell;
        vertical-align: top;
        width: 1%;

        .action {
            &.primary,
            &.cancel {
                @extend .abs-revert-to-action-secondary;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                margin: 0 0 0 -1px;
                white-space: nowrap;
                width: auto;
            }
        }

        .secondary {
            bottom: 5px;
            left: $mobile-cart-padding;
            position: absolute;
        }
    }

    .action.check {
        @extend .abs-action-button-as-link;
        font-weight: $font-weight__regular;
    }

    .fieldset {
        display: table;
        width: 100%;

        > .field {
            > .label {
                display: none;
            }
        }
    }

    .field {
        display: table-cell;
    }
}

//
//  Discount Block Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-discount-block-desktop {
        .block {
            @include abs-blocks-2columns();

            > .title {
                border: 0;
                padding: 0 0 $indent__s;

                strong {
                    font-size: $font-size__base;;
                }
            }

            .content {
                padding: 0 0 $indent__base;
            }
        }

        .actions-toolbar {
            .secondary {
                bottom: -30px;
                left: 0;
                position: absolute;
            }
        }
    }
}
