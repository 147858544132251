/**
 *
 * Common
 *
 */

.checkout-cart-index {

    .column.main {
        > button.action.primary.checkout {
            display: none;
        }
    }
    .modal-popup._show .modal-inner-wrap {
        max-width: 600px;
    }
    .cart-container .checkout-methods-items .action.primary {
        width: 100%;
        background-color: #ff9400;
        border-color: #cc7600;
        font-size: 16px;
    }

    .order-items.table-wrapper,
    .cart.table-wrapper {
        .col.price,
        .col.qty,
        .col.subtotal,
        .col.msrp {
            text-align: center;
        }
        .col.item {
            padding: 17px 8px 10px;
            display: flex;
            .product-item-photo {
                width: 30%;
            }
            .product-item-details {
                padding-left: 1rem;
            }
        }
        td.col.price,
        td.col.subtotal {
            padding: 16px 8px 10px;
            span.price {
                font-size: 1.2rem;
            }
        }
        .col.qty {
            padding-top: 10px;
			.p2-qty-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				.qty {
					&-decrease,
					&-increase {
						height: 40px;
						width: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 1px solid #c8c8c8;
						font-size: 18px;
						font-weight: 600;
						cursor: pointer;
						user-select: none;
						transition: background 0.25s ease;
						&:hover,
						&:active {
							background: darken(#ffffff, 5%);
						}
					}
				}
				input {
					height: 40px;
					width: 48px;
					border-left: 0;
					border-right: 0;
					margin: 0;
					&:focus {
						box-shadow: none;
					}
					&.valid {
						border-color: #c8c8c8;
					}
				}
			}
        }
        .col.actions {
            padding-top: 13px;
        }
    }

    #shopping-cart-table {
        .product-item-name {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .actions-toolbar {
        margin: 0;
        padding: 0;
        a.action.action-edit {
            display: none;
        }
        a.action.action-delete {
            color: #000;
            background-color: transparent;
            border: none;
            font-size: 12px;
            letter-spacing: 0;
            font-weight: 600;
            text-transform: none;
            padding: 0 6px;
            box-shadow: none;
            &:hover {
                color: #000;
                border-width: 0;
                background-color: transparent;
                text-decoration: underline;
            }
            &:focus {
                background: transparent;
            }
            &:after {
                @include material-icon('delete');
            }
            span {
                display: none;
            }
        }
    }

    div#block-giftcard,
    #gift-options-cart {
        display: none;
    }

    .cart-summary {

        display: flex;
        flex-wrap: wrap;

        .action.primary {
            order: 10;
            width: 100%;
        }

        .block .title > strong {
            margin: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 0;
            font-size: 16px;
            font-weight: 400;
        }
        .summary {
            margin: 0;
            padding: 1rem 0;
            background: transparent;
            &.title {
                font-weight: 600;
                width: 100%;
            }
        }
        #block-shipping {
            display: none;
        }
        #block-discount,
        #block-giftcard {
            margin: 0;
            width: 100%;
            .fieldset.coupon {
                margin-bottom: 20px;
            }
        }
        .cart-totals {
            width: 100%;
            table {
                margin: 0;
            }
        }
        .abs-sidebar-totals .grand .mark,
        .cart-totals .grand .mark,
        .opc-block-summary .table-totals .grand .mark,
        .abs-sidebar-totals .grand .amount,
        .cart-totals .grand .amount,
        .opc-block-summary .table-totals .grand .amount {
            padding: 7px 0;
        }
    }
    button.action.update {
        display: flex !important;
        background: #053d6f !important;
        color: #fff !important;
        align-items: center;
        margin-left: auto;
        font-size: 1rem;
        padding: 1rem;
        box-shadow: none;
        &:before {
            margin: 0 0.25rem;
        }
    }
}

/**
 *
 * Mobile
 *
 */

@include max-screen($screen__m) {
    .checkout-cart-index {
        .page-title-wrapper .page-title {
            margin-top: 1rem;
            font-size: 28px;
            font-weight: 700;
        }
    }
    .cart.items.table {
        .item-info {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .col.actions {
                position: absolute;
                top: 0;
                right: 0;
            }

            .col.item {
                width: 100%;

                .item-options {
                    margin: 5px 0;
                    font-size: 14px;
                }
                .product-item-details {
                    padding-left: 1rem;
                }
            }

            .col.subtotal,
            .col.price {
                width: 50%;
            }

            .col.qty {
                width: 100%;
                order: 10;
            }
        }
    }
}
@include min-screen($screen__l) {
    #shopping-cart-table {
        .col {
            &.item {
                .product-item {
                    &-photo {
                        width: 50%;
                    }
                    &-name {
                        font-size: 1.2rem;
                    }
                }
                .item-options {
                    margin: 5px 0;
                    font-size: 14px;
                }
            }
        }
    }
}
// Tablet  
@include screen($screen__m, $screen__l) {
    .checkout-cart-index {
        .page-main {
            padding: 0 2rem !important;
        }
        .cart-summary > .action.primary {
            order: 10;
            width: 100%;
            margin: 2rem 0 3rem;
        }
        .cart-container {
            table > caption {
                clip: unset;
                height: auto;
                width: auto;
                position: relative;
                text-align: left;
                font-size: 20px;
                font-weight: 600;
            }
            button.action.update {
               font-size: 12px;
            }
            .checkout-methods-items .action.primary {
                font-size: 12px;
            }
            .action.apply.primary {
                font-size: 12px;
            }
        }
        #shopping-cart-table {
            .col.item {
                .product-item-photo {
                    max-width: 60px;
                    padding: 0;
                }
            }
        }
    }
}
// Tablet Landscape 
@include screen(1024px, 1239px){
    .checkout-cart-index {
        .cart-container {
            table > caption {
                display: none;
            }
        }
    }
}
