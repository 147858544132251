.all-brands {
  .all-brands__list {
    list-style: none;
    column-count: 4;
    padding: 0;
    margin: 20px 0 40px 0;

    li {
      margin: 0 0 5px;
    }

    a {
      text-decoration: none;
      color: inherit;
      font-weight: 700;
      padding: 3px;
      display: block;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


/* TABLET */
@include screen($screen__m, $screen__l){
  .all-brands {
    .all-brands__list {
      column-count: 3;
    }
  }
}

/* MOBILE */
@include max-screen($screen__m){
  .all-brands {
    .all-brands__list {
      column-count: 2;
    }
  }
}
