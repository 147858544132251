@mixin petworld-primary-button {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-button__border,
        $_button-border-hover: $polarcore-primary-button__border,
        $_button-border-active: $polarcore-primary-cart-button__border,
        $_button-color: $polarcore-primary-button__color,
        $_button-background: $petworld-orange__color,
        $_button-background-hover: darken($petworld-orange__color, 8%),
        $_button-background-active: $petworld-orange__color
    );
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    box-shadow: 0 3px 0 0 darken($petworld-orange__color, 16%);
    border-radius: 4px;
}
