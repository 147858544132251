//
//  Utils
//  _____________________________________

@mixin am-flex(
    $horiz-pos: none,
    $vert-pos: none,
    $wrap: none
) {
    & {
        display: flex;
    }

    @if not ($horiz-pos = none) {
        justify-content: $horiz-pos;
    }

    @if not ($vert-pos = none) {
        align-items: $vert-pos;
    }

    @if not ($wrap = none) {
        flex-wrap: $wrap;
    }
}

@mixin am-word-break {
    & {
        word-wrap: break-word;
        word-break: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}

@mixin amscroll-arrow-icon {
    width: 14px;
    height: 28px;
    background: url('${amscroll-module__path}/images/arrow.svg');
}

@mixin amscroll-chevron-icon {
    width: 24px;
    height: 24px;
    background: url('${amscroll-module__path}/images/chevron.svg');
}

@-webkit-keyframes amScrollRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
