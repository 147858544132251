.meganav-active .page-wrapper .meganav_wrapper {
  visibility: visible;
  overflow: auto
}

.meganav-active .page-wrapper .meganav_wrapper .open-mobile {
  padding-bottom: 2rem
}

.meganav__item--see-all {
  display: none !important;
}

.meganav_wrapper ul {
  list-style: none;
}

@media only screen and (min-width: 1034px) {
  .meganav_wrapper {
    width:100%;
    margin: auto;
    background: transparent;
    justify-content: center;
    display: flex
  }

  .meganav_wrapper .meganav {
    display: flex;
    width: 100%;
    max-width: 1440px
  }

  .meganav_wrapper .meganav div#menu-button {
    display: none
  }

  .meganav_wrapper .meganav ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0
  }

  .meganav_wrapper .meganav ul a {
    font-weight: 700;
    text-align: left
  }

  .meganav_wrapper .meganav ul [data-id="ih8pNWbFmLS"]:before {
    content: url(https://www.petworld.no/static/version1732/frontend/Petworld/Theme/nb_NO/images/xdoggybox.png.pagespeed.ic.AWi1xM9ijL.png);
    margin-left: 1.75rem;
    margin-top: .3rem
  }

  .meganav_wrapper .meganav ul [data-id="ih8pNWbFmLS"] a {
    padding-left: .75rem
  }

  .meganav_wrapper .meganav ul [data-id="iBxIEQuVVXB"] a {
    border-right: 2px solid #fff;
    padding-right: 2rem
  }

  .meganav_wrapper .meganav ul>li{
    position: static;
    width: auto;
    padding: 0;
    margin: 0;
    text-align: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .meganav_wrapper .meganav ul>li.level0 {

    transition: .2s;

    &[data-id="iBxIEQuVVXB"] {
      margin-right: auto;
    }

    &:hover {
      > a {
        color: #4A45A6;
        text-decoration: none;
      }
    }

    &.has-children {
      &:hover,
      &.meganav__item--open{
        background: #ff7800;
        > a {
          color: #fff;
        }
      }
    }
  }

  .meganav_wrapper .meganav ul>li a {
    color: #fff;
    font-size: 15px;
    padding: 1.15rem 0.8rem;
    display: flex;
    align-items: center
  }

  .meganav_wrapper .meganav .meganav__list {
    display: none;
    position: absolute;
    left: 0;
    height: auto;
    background-color: #fff;
    z-index: 99;
    top: 100%;
    width: 1440px;
    flex-wrap: wrap;
    overflow: visible;
    min-height: 485px;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,.5);
  }

  .meganav_wrapper .meganav ul>li>ul.meganav__list--open {
    display: block;
  }

  .meganav_wrapper .meganav ul>li>ul ul {
    height: 100%
  }

  .meganav_wrapper .meganav ul>li>ul>li {
    position: relative;
    width: 20%;
    padding: 0;
    background-color: #fff;
    text-align: initial;
    display: flex;
  }

  .meganav_wrapper .meganav ul>li>ul>li.has-children {
    transition: all 150ms ease
  }

  .meganav_wrapper .meganav ul>li>ul>li.has-children:after {
    content: '>';
    float: right;
    color: #1a1a1a;
    font-size: 1.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    align-self: center;
    position: absolute;
    right: 1rem;
  }

  .meganav_wrapper .meganav ul>li>ul>li.has-children:hover {
    background: #edf1f8
  }

  .meganav_wrapper .meganav ul>li>ul>li.has-children:hover:after {
    content: '-';
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 18.2%
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover {
    position: static
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul {
    display: block;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20%;
    width: 20%;
    z-index: 1;
    background: #fff;
    margin: 0;
    padding: 0;
    justify-content: initial;
    align-content: flex-start!important;
    max-height: 500px;
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul a {
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    color: #1a1a1a;
    width: 100%;
    padding: 7px 10px;
    line-height: 1;
    font-size: 14px
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul a:hover {
    background: #edf1f8
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li {
    position: static;
    padding: 0;
    background: transparent;
    width: 100%;
    margin: .03rem
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li:before {
    content: "\E315";
    font-family: 'Material icons';
    color: #1a1a1a;
    margin-right: .5rem;
    font-size: 1.2rem;
    display: none
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li:after {
    position: static;
    margin-left: -1.75rem
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li:hover>ul {
    width: 100%;
    left: 100%;
    background: #fff
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li:hover>ul>li {
    width: 100%
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li:hover>ul>li>a:before {
    display: none
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li>a:hover {
    background: #edf1f8
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>li>a:hover:before {
    text-decoration: none
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>ul {
    min-height: initial;
    display: block;
    position: relative;
    white-space: nowrap;
    left: 0;
    top: 0;
    margin: 0;
    padding: 1rem 0;
    box-shadow: none;
    width: 100%!important;
    background: transparent
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>ul a {
    font-weight: 400
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>ul li {
    padding: .3rem 0;
    margin: 0;
    width: 100%
  }

  .meganav_wrapper .meganav ul>li>ul>li:hover>ul>ul li ul {
    display: none
  }

  .meganav_wrapper .meganav ul>li>ul>li ul {
    position: static;
    border: none;
    background-color: transparent;
    margin-top: .75rem;
    padding: .5rem;
    width: 100%
  }

  .meganav_wrapper .meganav ul>li:hover ul ul,.meganav_wrapper .meganav ul>li .open ul ul {
    display: none
  }

  .meganav_wrapper li.meganav__item.level0 {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap
  }

  .meganav_wrapper li.meganav__item.level0>ul>img {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    max-width: 1150px;
    height: 100%;
    max-height: 490px;
    margin-left: 3px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    -o-object-position: 50% 93%;
    object-position: 50% 93%
  }

  .meganav_wrapper li.meganav__item.level0 a {
    color: #202020;
    font-weight: 700;
  }

  .meganav_wrapper li.meganav__item.level0>a {
    text-transform: uppercase
  }

  .meganav_wrapper li.meganav__item.level0.has-children:hover>a {
    color: #fff;
    position: relative;
    text-decoration: none!important
  }

  .meganav_wrapper li.meganav__item.level0.has-children:hover>a:after {
    content: '\E313';
    font-family: 'Material Icons';
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    display: block;
    width: 100%;
    text-align: center
  }

  .meganav_wrapper li.meganav__item.level0.has-children li.meganav__item--open>a {
    border-left: 10px solid #4b5699!important
  }

  .meganav_wrapper li.meganav__item.level1>ul>img {
    display: block;
    position: absolute;
    right: 10%;
    top: 0;
    width: 30%;
    height: 490px;
    margin-left: 3px
  }

  .meganav_wrapper li.meganav__item.level1 a {
    display: block;
    color: #1a1a1a;
    font-size: 14px;
    padding: .6rem 1rem;
    line-height: 1;
    width: 100%;
  }

  .meganav_wrapper li.meganav__item.level1:hover {
    background: #edf1f8;
    border-radius:0;
    a {
      text-decoration: underline
    }
  }

  .meganav_wrapper li.meganav__item.level1:hover ul>ul {
    display: none
  }

  .meganav_wrapper li.meganav__item.level2 {
    padding: 0
  }

  .meganav_wrapper li.meganav__item.level2 a {
    display: flex;
    padding: 0 .5rem;
    font-size: 1.2rem
  }

  .meganav_wrapper li.meganav__item.level2:after {
    left: 6%!important;
    width: 12px
  }

  .meganav_wrapper .meganav__item--open {
    display: block!important
  }

  .meganav_wrapper .hidden {
    display: none!important
  }
}

@media only screen and (max-width: 1033px) {
  .meganav_wrapper div.meganav ul {
    border-top:1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
    box-sizing: border-box;
    background: #f9f9f9;
    position: fixed;
    top: 60px;
    z-index: 9999999;
    height: calc(100% - 60px);
    width: 100%;
    display: none;
    overflow: auto;
    left: 0
  }

  .meganav_wrapper div.meganav ul img {
    display: none
  }

  .meganav_wrapper div.meganav ul>li {
    float: none;
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid rgba(120,120,120,.2)
  }

  .meganav_wrapper div.meganav ul>li a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    box-sizing: border-box;
    padding: 17px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #444;
    font-weight: 700;
    width: 100%
  }

  .meganav_wrapper div.meganav ul>li>ul {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    background: rgba(0,0,0,.05);
    height: auto
  }

  .meganav_wrapper div.meganav ul>li>ul>li {
    height: auto
  }

  .meganav_wrapper div.meganav ul>li>ul>li a {
    padding-left: 25px
  }

  .meganav_wrapper div.meganav ul>li>ul>li>ul {
    background: rgba(0,0,0,.05);
    border: none;
    box-shadow: none;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    border: none
  }

  .meganav_wrapper div.meganav ul>li>ul>li>ul>li {
    padding-left: 0
  }

  .meganav_wrapper div.meganav ul>li>ul>li>ul>li>ul {
    background: transparent
  }

  .meganav_wrapper div.meganav #menu-button {
    display: block;
    color: #ddd;
    font-size: 12px;
    font-weight: 700;
    position: fixed;
    z-index: 999999;
    width: 20px;
    height: 20px;
    top: 22px;
    left: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .meganav_wrapper div.meganav #menu-button:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 2px;
    width: 20px;
    background: #681cff;
    content: '';
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 150ms all ease-out
  }

  .meganav_wrapper div.meganav #menu-button:after {
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    height: 9px;
    width: 20px;
    border-top: 2px solid #681cff;
    border-bottom: 2px solid #681cff;
    content: '';
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 150ms all ease-out
  }

  .meganav_wrapper div.meganav #menu-button>label {
    top: 100%;
    color: #1a1a1a;
    margin: .2rem 0;
    font-weight: 400;
    position: absolute;
    top: 2.2rem;
    left: .35rem;
    font-size: 10px;
    display: none;
  }

  .meganav_wrapper div.meganav #menu-button.menu-opened:before {
    top: 8px;
    background: #681cff;
    width: 20px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }

  .meganav_wrapper div.meganav #menu-button.menu-opened:after {
    top: 8px;
    border: 0;
    height: 2px;
    width: 20px;
    background: #681cff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
  }

  .meganav_wrapper div.meganav .has-children {
    position: relative;

      &.level0:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
      }
  }

    .meganav_wrapper div.meganav .has-children.submenu-opened.level0:before {
        transform: rotate(0);
    }

  .meganav_wrapper div.meganav .has-children>a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
  }

    .meganav_wrapper div.meganav .has-children .open-icon {
        position: absolute;
        right: 0;
    }

  .meganav_wrapper div.meganav .has-children .open-icon:before,
  .meganav_wrapper div.meganav .has-children:not(.level0):before {
    position: absolute;
    top: 18px;
    right: 19px;
    display: block;
    width: 2px;
    height: 8px;
    background: #444;
    content: ''
  }

  .meganav_wrapper div.meganav .has-children .open-icon:after,
  .meganav_wrapper div.meganav .has-children:not(.level0):after {
    position: absolute;
    top: 21px;
    right: 16px;
    width: 8px;
    height: 2px;
    display: block;
    background: #444;
    content: ''
  }

  .meganav_wrapper div.meganav .has-children .open-icon:before,
  .meganav_wrapper div.meganav .has-children:not(.level0):before,
  .meganav_wrapper div.meganav .has-children .open-icon:after,
  .meganav_wrapper div.meganav .has-children:not(.level0):after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 150ms all ease-out
  }

  .meganav_wrapper div.meganav .has-children.submenu-opened:before,
  .meganav_wrapper div.meganav .has-children.submenu-opened:not(.level0):before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }

  .meganav_wrapper div.meganav .has-children.submenu-opened:after,
  .meganav_wrapper div.meganav .has-children.submenu-opened:not(.level0):after{
    background: #444
  }

  li.has-children {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
  }

  li.active>a {
    border-left: 10px solid #3c3c3c!important
  }
}

.obfuscator {
  display: block;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: calc(100% - 175px);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 30;
  visibility: hidden
}

.obfuscator.is-visible {
  visibility: visible
}

@media only screen and (max-width: 767px) {
  .obfuscator {
    top:0;
    height: 100%;
    display: none
  }

  .hide_on_mobile {
    display: none!important
  }

  .meganav_wrapper div.meganav #menu-button>label {
    display: none;
  }

}

@media only screen and (min-width: 768px) {
  .hide_on_tablet {
    display:none!important
  }
}

@media only screen and (min-width: 1280px) {
  .hide_on_desktop {
    display:none!important
  }
}

.meganav_wrapper div.meganav ul li.meganav__item--back,
.meganav_wrapper div.meganav ul li.meganav__item--title {
  display: none;
}
