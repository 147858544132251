.page-footer{
    background: transparent;
    padding: 0;
    .footer-content, .footer.content{
        background: transparent;

        padding: 60px 0 0 0;
        border-top: 3px solid #4A45A6;

        section.container {
            padding: 0 15px;
            color: #2a2a2a;
        }

        a, p {
            color: #2a2a2a;
        }

        .widget.block.block-static-block{
            margin: 0;
            h3{
                font-size: 22px;
                font-weight: 700;
                letter-spacing: 1px;
                margin-bottom: 2rem;
            }
            .petworld-logo-footer{
                margin-bottom: 2rem;
                img{
                    margin-bottom: 2rem;
                }
                p{
                    font-size: 16px;
                }
            }
            .kundeservice{
                margin-bottom: 6rem;
                li{
                    a{
                        font-size: 16px;
                        line-height: 30px;
                        font-weight: 400;
                        &:before{
                            display: none;
                        }
                    }
                }
            }
            .nyhetsbrev{
                > p{
                    font-size: 16px;
                    margin-bottom: 2rem;
                }
                .block.newsletter{
                    width: 100%;
                    .title{
                        display: none;
                    }
                }
            }
            .social-media{
                padding-right: 0;

                li{
                    display: inline-block;
                    margin-right: 10px;
                    padding: 0;
                    a{
                        display: block;
                        width: 60px;
                        height: 60px;
                        padding: 5px;d

                        &:hover {
                            opacity: 0.8;
                        }
                        img{
                            max-width: 100%;
                            display: block;
                            width: 100%;
                        }
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .block.newsletter{
        width: 100%;
        
        .form.subscribe{
            display: flex;

            .field.newsletter{
                .control{
                    &:before{
                        display: none;
                    }
                    input{
                        padding-left: 9px;
                    }
                }
            }
            .actions{
                height: 44px;
                width: auto;
                flex-shrink: 0;

                .action.subscribe{
                    border-radius: unset;
                    background: #4A45A6;
                    padding: 0.6rem 2rem;
                    height: 100%;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

.copyright{
    background: #4A45A6;
    color: #fff;
}



/* Mobile */
@include max-screen( $screen__m ){
    .page-footer{
        .kundeservice{
            margin-bottom: 2rem !important;
        }
        img.logos{
            margin-bottom: 3rem;
        }
        .block.newsletter{
            .form.subscribe{
                .action.subscribe{
                    span{
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

/* DESKTOP */
@include min-screen ($screen__m){
    .page-footer{
        .block.newsletter{
            .form.subscribe{
                flex-wrap: wrap;

                .field.newsletter{
                    width: 100%;
                    margin-bottom: 5px;

                    .control{
                        input{
                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
                .actions{
                    width: 100%;
                    margin-top: 5px;
                }
            }
        }
    }
}
