$petworld-dark-grey__color: #595959;
$petworld-orange__color: #ed7c37;
$petworld-light-grey__color: #f9f9f9;
$petworld-dark-blue__color: #373c6c;
$petworld-blue__color: #4b5699;
$petworld-cod-grey__color: #1a1a1a;

$petworld-member-blue__color: #3498db;
$petworld-red__color: #D14A3F;
$petworld-medlem-blue__color: #3498db;

$petworld-red-label__color: $petworld-red__color;
$petworld-special-price__color: $petworld-red__color;

$petworld-button__background-color: $petworld-orange__color;
$petworld-button__box-shadow: 0 3px 0 0 darken($petworld-button__background-color, 16%);

$white__color: #fff;
$color-concrete: #f2f2f2;
$petxl-orange-main__color: #f7921e;
$petxl-icons-orange__color: #f8a03c;
$petxl-text__color: #474445;
$petxl-top-links-background__color: #f5f5f5;
$petxl-product-page-tabs__color: #161616;
$petxl-product-page-background__color: #fcf8f2;
$petxl-product-qty-btn__color: #595959;
$petxl-special-price__color: #f7921e;
$petxl-old-price__color: #BFBFBF;

$hundsomhobby-gray-main: #263238;
$hundsomhobby-gray-main-hover: #000000;
$hundsomhobby-gray-light: #d8d8d8;

$hundsomhobby-button__background-color: $hundsomhobby-gray-main;
$hundsomhobby-button__box-shadow: 0 3px 0 0 darken($hundsomhobby-button__background-color, 16%);
