
.cms-page-view {
    .center {
        text-align: center;
    }
    .page-wrapper {
        overflow: hidden;
    }
    .breadcrumbs {
        display: none;
    }
    #maincontent .columns {
        margin-top: 0;
    }

    .page-title-wrapper {
        display: none;
    }

    .page-main {
        .cms-container {
            max-width: 1000px;
            margin: 10px auto;
        }
        h2 {
            font-size: 26px;
            font-weight: 700;
            line-height: 39px;
            margin-top: 30px;
            margin-bottom: 0;
            color: #474445;
        }
        h3 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
            color: #474445;
        }
        h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
            color: #474445;
        }
        ul, ol {
            font-size: 16px;
            margin-top: .5rem;
            margin-bottom: .5rem;
            color: #474445;
            font-weight: 400;
            line-height: 24px;
        }
        li, p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: #474445;
        }
        picture {
            display: inline-block;
            vertical-align: top;
            padding-bottom: 30px;
            img {
                vertical-align: top;
            }
        }
        a {
            &:hover {
                color: grey;
            }
        }
        .actions {
            padding: 30px 0;

        }
        .action.primary {
            background: #ed7c37;
            box-shadow: 0 3px 0 0 #d45c13;
            color: #fff;
            border: 1px solid #ed7c37;
            padding: 1.15rem 4.5rem;
            font-size: 20px;
            letter-spacing: 1px;
            font-weight: 700;
            font-family: 'Open Sans', 'Arial', sans-serif;
            border-radius: 0;
        }
        .bg-title h2 {
            background: #353d69;
            border-top: 5px solid #4b5699;
            border-bottom: 5px solid #4b5699;
            color: #fff;
            text-shadow: #4b5699 3px 3px 0;
            font-size: 84px;
            line-height: 1;
            text-transform: uppercase;
            font-weight: 800;
            font-family: 'Open Sans', 'Arial', sans-serif;
            text-align: center;
            padding: 93px 100%;
            margin: 0 -100% 16px;
        }
        .title-main {
            text-align: center;
            margin-bottom: 24px;
            h1 {
                border-bottom: 4px solid #4b5699;
                padding-bottom: 1rem;
                font-size: 32px;
                font-weight: 500;
                display: inline-block;
            }
        }
    }
}
.cms-page-view.store-petxl .page-main .title-main h1 {
    color: #f7921e;
    font-size: 24px;
    border-bottom-color: #f7921e;
}

@media only screen and (max-width: 767px) {
    .cms-page-view {
        .page-main {
            .bg-title h2 {
                font-size: 40px;
                padding: 50px 100%;
            }

            .title-main h1 {
                font-size: 24px;
            }
        }
    }
}
